import React, { useState } from "react";
import { FormContainer } from "components/Layout";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "services/authService";

import AuthLogo from "../AuthLogo";

import ForgotPasswordForm from "./ForgotForm";
import ForgotPasswordSuccess from "./ForgotSuccess";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const onFinish = (value: { email: string }) => {
    setEmail(value.email);
    setForgotSuccess(true);
    forgotPassword(value.email).catch(() => {});
  };

  const onCancel = () => {
    navigate("/login");
  };

  const [form] = Form.useForm();

  return (
    <FormContainer>
      <AuthLogo />
      {forgotSuccess ? (
        <ForgotPasswordSuccess email={email} />
      ) : (
        <ForgotPasswordForm
          onFinish={onFinish}
          form={form}
          onCancel={onCancel}
        />
      )}
    </FormContainer>
  );
};

export default ForgotPassword;
