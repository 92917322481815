import React, { useState } from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

interface CustomInputProps extends InputProps {}

const TrimInput: React.FC<CustomInputProps> = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (newValue === " ") {
      newValue = "";
    }
    newValue = newValue.replaceAll(".", " ").replaceAll(/\s{2,}/g, " ");

    setValue(newValue);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Input
      {...props}
      style={{ height: 36 }}
      value={value}
      onChange={handleChange}
      className={`${props.className || ""}`}
    />
  );
};

export default TrimInput;
