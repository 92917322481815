import React from "react";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { OpportunityRoles } from "models/opportunityModel";
import { useNavigate } from "react-router-dom";
import Table from "components/Table";
import { RowSection } from "components/Section";

interface Props {
  roles: OpportunityRoles[];
}

const RoleTable: React.FC<Props> = ({ roles }) => {
  const navigate = useNavigate();

  const handleButtonClick = (key: string) => {
    navigate(`/ai-workforce-potential?role=${encodeURIComponent(key)}`);
  };

  const columns = [
    {
      title: "Role Title",
      dataIndex: "title",
      key: "title",
      render: (_: string, record: OpportunityRoles) => (
        <div
          className="text-base font-normal leading-6 truncate w-auto"
          title={record.title}
        >
          {record.title}
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
      render: (_: string, record: OpportunityRoles) => (
        <RowSection
          value={record.totalEmployees}
          description={`in ${record.totalTeams} team(s)`}
        />
      ),
    },
    {
      title: "Productivity Gain",
      dataIndex: "annualProdGain",
      key: "annualProdGain",
      render: (_: string, record: OpportunityRoles) => (
        <RowSection
          value={record.annualProdGain}
          description={"per year"}
          isViewcurrency={true}
        />
      ),
    },
    {
      title: "Time Saved",
      dataIndex: "annualTimeSaved",
      key: "annualTimeSaved",
      render: (_: string, record: OpportunityRoles) => (
        <RowSection
          value={record.annualTimeSaved}
          description={"hours per year"}
        />
      ),
    },
    {
      title: "Detail",
      key: "actions",
      render: (_: string, record: OpportunityRoles) => (
        <Button
          type="primary"
          icon={<RightOutlined />}
          iconPosition="end"
          onClick={() => handleButtonClick(record.title)}
        >
          <span className="text-xs">VIEW ROLE</span>
        </Button>
      ),
    },
  ];

  return (
    <Table
      rootClassName="border-none pl-2"
      columns={columns}
      rowKey="title"
      dataSource={roles}
      pagination={false}
      scroll={{ y: 500, x: 800 }}
    />
  );
};

export default RoleTable;
