import React from "react";
import { Table as AntdTable } from "antd";
import "./style.css";
import { TableProps } from "antd/lib";

interface Props extends TableProps {}

const Table: React.FC<Props> = ({ ...props }) => {
  return (
    <div className="border-b border-gray900t2">
      <AntdTable
        className="custom-table"
        rowClassName={"border-b-2 bg-transparent"}
        {...props}
      />
    </div>
  );
};

export default Table;
