import React from "react";
import { ConnectWithUs } from "models/footerModel";
import { AppleIcon, GoogleIcon, InstagramIcon, XIcon } from "components/Icon";
import { IconProps } from "components/Icon/model";

interface Props extends ConnectWithUs {
  style?: IconProps;
}
interface IconComponent extends React.FC<IconProps> {}

const iconComponents: {
  [key: string]: IconComponent;
} = {
  Instagram: InstagramIcon,
  Twitter: XIcon,
  Google: GoogleIcon,
  Apple: AppleIcon,
};

const getIconComponent = (name: string): IconComponent => {
  return iconComponents[name];
};

const FooterLink: React.FC<Props> = ({
  title,
  socialMediaIcons = [],
  style,
}) => (
  <div className="flex items-center">
    <span className="text-footer-title uppercase font-bold">{title}</span>
    {socialMediaIcons.map((item, index) => {
      const IconComponent = getIconComponent(item.name);
      return (
        IconComponent && (
          <div key={index} className="leading-7">
            <div className="text-[32px] pl-6 cursor-pointer">
              <IconComponent to={item.url || ""} fill={style?.fill} />
            </div>
          </div>
        )
      );
    })}
  </div>
);

export default FooterLink;
