import React from "react";
import { Button, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";

interface Props {
  onCreate?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  onResendInvite?: () => void;
}

const DetailActions: React.FC<Props> = ({
  onCreate,
  onUpdate,
  onDelete,
  onResendInvite,
}) => {
  const content = (
    <div className="flex flex-col space-y-2">
      {onCreate && (
        <Button type="text" className="justify-start w-full" onClick={onCreate}>
          Create
        </Button>
      )}
      {onUpdate && (
        <Button type="text" className="justify-start w-full" onClick={onUpdate}>
          Edit
        </Button>
      )}
      {onResendInvite && (
        <Button
          type="text"
          className="justify-start w-full"
          onClick={onResendInvite}
        >
          Re-send invite
        </Button>
      )}
      {onDelete && (
        <Button type="text" className="justify-start w-full" onClick={onDelete}>
          Delete
        </Button>
      )}
    </div>
  );

  return (
    <Popover placement="right" content={content} trigger="click">
      <MoreOutlined className="cursor-pointer text-2xl" />
    </Popover>
  );
};

export default DetailActions;
