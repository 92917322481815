import React, { ReactNode } from "react";
import { Button } from "antd";

interface Props {
  name: string;
  onChange: (value: string) => void;
  buttonName?: string;
  buttonIcon?: ReactNode;
  isViewButton?: boolean;
}

const PageHeader: React.FC<Props> = ({
  name,
  onChange,
  buttonName,
  buttonIcon = <></>,
  isViewButton = true,
}) => {
  const handleButtonClick = () => {
    onChange(name);
  };

  const button = (
    <Button
      type="primary"
      icon={buttonIcon}
      iconPosition="start"
      className="h-9"
      onClick={() => handleButtonClick()}
    >
      {buttonName}
    </Button>
  );

  return (
    <div className="w-full">
      <div className="text-3xl font-black !text-gradient-base text-gradient-purpleblue float-left">
        {name}
      </div>
      {isViewButton && <div className="float-right">{button}</div>}
    </div>
  );
};

export default PageHeader;
