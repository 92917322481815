import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

import App from "./App";
import store from "./common/store";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/main.css";
import "./common/i18n";
import { customAntdTheme } from "./assets/styles/customTheme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={customAntdTheme}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
