import graphQLClient from "common/client/graphQLClient";
import { UploadFile } from "antd";
import {
  combineRolePermissions,
  transformPermissions,
  UpsertRolePayload,
} from "models/adminModel";

import {
  GET_ROLE,
  LIST_PERMISSIONS,
  LIST_ROLES,
  LIST_TENANT,
  UPLOAD_CSV,
  UPSERT_ROLE,
} from "./adminQueries";

export const getTenants = async () => {
  const response = await graphQLClient.query({
    query: LIST_TENANT,
  });
  return response.data.listTenant;
};

export const uploadCSV = async (
  file: UploadFile,
  skipError: boolean = false
) => {
  const response = await graphQLClient.mutate({
    mutation: UPLOAD_CSV,
    variables: { file, skipError },
  });
  return response;
};

export const getRoles = async () => {
  const response = await graphQLClient.query({
    query: LIST_ROLES,
  });
  return response.data.listRolesWithNbOfPermissions;
};

export const upsertRole = async (data: UpsertRolePayload) => {
  const response = await graphQLClient.mutate({
    mutation: UPSERT_ROLE,
    variables: { input: data },
  });
  return response;
};

export const getRoleDetail = async (id: string) => {
  const response = await graphQLClient.mutate({
    mutation: GET_ROLE,
    variables: {
      id,
    },
  });
  return response.data.getRoleDetail;
};

export const getPermissions = async () => {
  const response = await graphQLClient.query({
    query: LIST_PERMISSIONS,
  });
  return response.data.listAllPermissions;
};

export const getRolePermissions = async (id?: string) => {
  const allPermissions = await getPermissions();
  let rolePermissions = transformPermissions(allPermissions);
  let name = "";
  let description = "";

  if (id) {
    const data = await getRoleDetail(id);
    rolePermissions = combineRolePermissions(rolePermissions, data.permissions);
    name = data.name;
    description = data.description;
  }

  return {
    name,
    description,
    rolePermissions,
  };
};
