import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const CaretDownIcon: React.FC<IconProps> = ({
  stroke = theme.color.icon.black,
}) => (
  <span className="ant-menu-item-icon">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.25 7.5L10 13.75L3.75 7.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default CaretDownIcon;
