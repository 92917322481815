import { gql } from "@apollo/client/core";

const UPLOAD_CSV = gql`
  mutation UploadEmployeeCsv($file: Upload!, $skipError: Boolean) {
    uploadEmployeeCsv(file: $file, skipError: $skipError) {
      success
      error {
        missingInvalidCells
        duplicatedRows
      }
    }
  }
`;

const LIST_TENANT = gql`
  query ListTenant {
    listTenant {
      id
      name
    }
  }
`;

const LIST_ROLES = gql`
  query ListRolesWithNbOfPermissions {
    listRolesWithNbOfPermissions {
      id
      name
      description
      totalPermissions
    }
  }
`;

const LIST_PERMISSIONS = gql`
  query ListAllPermissions {
    listAllPermissions {
      id
      name
      action
    }
  }
`;

const UPSERT_ROLE = gql`
  mutation UpsertRole($input: RoleInput!) {
    upsertRole(input: $input) {
      id
      name
      description
    }
  }
`;

const GET_ROLE = gql`
  query GetRoleDetail($id: ID!) {
    getRoleDetail(id: $id) {
      id
      name
      description
      permissions {
        id
        name
        action
      }
    }
  }
`;

export {
  UPLOAD_CSV,
  LIST_TENANT,
  LIST_ROLES,
  LIST_PERMISSIONS,
  UPSERT_ROLE,
  GET_ROLE,
};
