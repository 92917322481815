import React from "react";
import { Row, Col } from "antd";
import formatNumber, { FormattedNumber } from "utils/formatNumber";

interface Props {
  value: number | string;
  description?: string;
  isViewcurrency?: boolean;
  isTextRow?: boolean;
}

const RowSection: React.FC<Props> = ({
  value,
  description,
  isViewcurrency = false,
  isTextRow = false,
}) => {
  const renderDescription = () => (
    <Col span={24}>
      <div className="text-sm font-normal leading-5 text-left text-gray600 h-5">
        {description}
      </div>
    </Col>
  );

  if (isTextRow) {
    return (
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <div className="text-3xl font-black leading-7">{value}</div>
        </Col>
        {description && renderDescription()}
      </Row>
    );
  }

  const { amount, currency }: FormattedNumber = formatNumber(value as number);
  return (
    <Row gutter={[16, 0]}>
      <Col span={24}>
        <div className="text-3xl font-black leading-7">
          {isViewcurrency && "$"}
          {amount}
          {currency}
        </div>
      </Col>
      {description && renderDescription()}
    </Row>
  );
};

export default RowSection;
