import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const ApertureIcon: React.FC<IconProps> = ({
  stroke = theme.color.icon.default,
}) => (
  <span className="ant-menu-item-icon">
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none">
      <path
        d="M18.5 31.5C25.9558 31.5 32 25.4558 32 18C32 10.5442 25.9558 4.5 18.5 4.5C11.0442 4.5 5 10.5442 5 18C5 25.4558 11.0442 31.5 18.5 31.5Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 4.5L23.6609 18.9422"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.81396 11.25L21.889 14.0062"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.81396 24.7504L16.7421 13.0645"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4998 31.5008L13.3389 17.0586"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1858 24.7504L15.1108 21.9941"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1859 11.25L20.2578 22.9359"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default ApertureIcon;
