import React from "react";
import { Row } from "antd";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";

import InfoScreen from "./InfoScreen";

interface Props {
  children?: React.ReactNode;
}

const TenantLayout: React.FC<Props> = ({ children }) => {
  return (
    <MainLayout>
      <CardLayout>
        <Row className="bg-transparent rounded-3xl">
          <InfoScreen />
        </Row>
        <BoxLayout className="bg-white shadow-gradient">{children}</BoxLayout>
      </CardLayout>
    </MainLayout>
  );
};

export default TenantLayout;
