import React, { ReactNode } from "react";
import { Select, SelectProps } from "antd";
import { SearchIcon } from "components/Icon";
import theme from "assets/styles/theme";

const { Option } = Select;

export interface OptionType {
  value: string;
  label: string;
}

interface Props {
  selectedItem?: string;
  onSelectionChange: (value: string) => void;
  options: OptionType[];
  className?: string;
  placeholder?: string;
  suffixIcon?: ReactNode;
  showSearch?: boolean;
}

type LabelRender = SelectProps["labelRender"];

const SearchDropdown: React.FC<Props> = ({
  selectedItem,
  onSelectionChange,
  options,
  className = "min-w-80 h-11 text-black",
  placeholder,
  suffixIcon = <></>,
  showSearch = false,
}) => {
  const filterOption = (input: string, option?: React.ReactElement) => {
    if (option && option.props.children) {
      return option.props.children
        .toString()
        .toLowerCase()
        .includes(input.toLowerCase());
    }
    return false;
  };

  const labelRender: LabelRender = ({ value }) => {
    return (
      <div className="flex items-center">
        <SearchIcon stroke={theme.figmaColor.gray800} />
        <span className="pl-2 text-base font-normal leading-6">
          {placeholder}{" "}
        </span>
        <span className="pl-2 text-base font-bold leading-6"> {value} </span>
      </div>
    );
  };

  return (
    <Select
      showSearch={showSearch}
      className={className}
      optionFilterProp="children"
      onChange={onSelectionChange}
      value={selectedItem}
      filterOption={filterOption}
      labelRender={labelRender}
      suffixIcon={suffixIcon}
    >
      {options.map((opt) => (
        <Option key={opt.value} value={opt.value}>
          {opt.label}
        </Option>
      ))}
    </Select>
  );
};

export default SearchDropdown;
