import { FooterColumn as Model } from "models/footerModel";
import React from "react";

interface Props extends Model {}

const handleClick = (url: string) => {
  window.open(url, "_blank");
};

const FooterColumn: React.FC<Props> = ({ title, links }) => (
  <div className="flex flex-col">
    <h1 className="text-footer-title text-base uppercase font-bold leading-[18px] pb-8">
      {title}
    </h1>
    {links.map((item, index) => (
      <div key={index} className="text-lg leading-7 pb-4">
        {item.url ? (
          <div
            className="cursor-pointer text-gray700"
            dangerouslySetInnerHTML={{ __html: item.text }}
            onClick={() => handleClick(item.url as string)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: item.text }} />
        )}
      </div>
    ))}
  </div>
);

export default FooterColumn;
