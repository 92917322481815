import React, { ReactNode } from "react";
import { Tabs } from "antd";

interface TabContent {
  title: string;
  content: ReactNode;
}

interface Props {
  tabs: TabContent[];
}

const HorizontalTabs: React.FC<Props> = ({ tabs }) => {
  const tabItems = tabs.map((tab, index) => ({
    key: index.toString(),
    label: tab.title,
    children: <div>{tab.content}</div>,
  }));

  return (
    <Tabs
      tabPosition="left"
      defaultActiveKey="0"
      type="line"
      className="w-full"
      items={tabItems}
    />
  );
};

export default HorizontalTabs;
