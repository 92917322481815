import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Adoption } from "models/opportunityModel";
import { MetricsSection } from "components/Section";
import { getAdoptionSlider } from "services/opportunityService";
import { RoadMapIcon } from "components/Icon";
import formatNumber from "utils/formatNumber";

import AdoptionSlider from "./AdoptionSlider";

interface Props extends Adoption {}

const AdoptionScreen: React.FC<Props> = ({
  annualProdGain,
  totalEmployees,
  annualTimeSaved,
}) => {
  const [sliderPercent, setSliderPercent] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const fetchAdoptionSlider = async () => {
      try {
        const { adoptionSlider } = await getAdoptionSlider();
        if (isMounted) {
          adoptionSlider ? setSliderValue(adoptionSlider) : setSliderValue(0);
        }
      } catch {
        if (isMounted) setSliderValue(0);
      } finally {
        setLoading(false);
      }
    };

    fetchAdoptionSlider();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSliderChange = (value: number) => {
    setSliderPercent(value);
  };

  if (loading) {
    return <></>;
  }

  const { amount: employees } = formatNumber(totalEmployees * sliderPercent);

  return (
    <Card bordered={true} className="shadow-gradient">
      <Row gutter={[16, 16]} className="flex flex-col h-full">
        <Col>
          <RoadMapIcon />
        </Col>
        <Col className="text-2xl font-light leading-8 text-left text-black">
          Explore Adoption
        </Col>
        <Col className="text-base font-normal leading-6 text-left text-black">
          Adjust the slider to see the what-if benefits and time to optimal
          adoption for your workforce
        </Col>
        <Col>
          <AdoptionSlider
            defaultValue={sliderValue}
            onValueChange={handleSliderChange}
            description={`${employees} employees`}
          />
        </Col>
        <Col className="border-b border-gray900t2"></Col>
        <Col>
          <MetricsSection
            value={annualTimeSaved * sliderPercent}
            description={"Hours saved / year"}
          />
        </Col>
        <Col>
          <MetricsSection
            value={annualProdGain * sliderPercent}
            description={"Annual Productivity Gain"}
            isViewCurrency
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AdoptionScreen;
