import React, { useEffect, useState } from "react";
import { Empty, Layout, Row } from "antd";
import { SearchDropdown } from "components/Dropdown";
import { getWorkforceTeamByName } from "services/workforceService";
import { ExploreTeam } from "models/workforceModel";
import parseError from "utils/error";
import Loader from "components/Loading/Loader";
import isEmpty from "lodash/isEmpty";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "hooks";
import { PageHeader } from "components/Header";

import TeamSection from "./TeamSection";

const { Content } = Layout;

interface Props {
  selectOptions: { value: string; label: string }[];
  onTeamChange: (value: ExploreTeam) => void;
  selectedTeams: ExploreTeam[];
}

const TeamScreen: React.FC<Props> = ({
  selectOptions,
  // onTeamChange,
  // selectedTeams,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const defaultTeam = query.get("team") as string;

  const [selectedItem, setSelectedItem] = useState<string>(
    defaultTeam || selectOptions[0].value
  );
  const [team, setTeam] = useState<ExploreTeam>();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState<string>("");

  const debouncedSelectedItem = useDebounce(selectedItem, 500);

  const fetchTeam = async (name: string) => {
    try {
      const data = await getWorkforceTeamByName(name);
      setTeam(data);
      setLoading(false);
    } catch (error_) {
      setError(parseError(error_));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSelectedItem) {
      setLoading(true);
      fetchTeam(debouncedSelectedItem);
      navigate(`?team=${encodeURIComponent(debouncedSelectedItem)}`, {
        replace: true,
      });
    }
  }, [debouncedSelectedItem, navigate]);

  const handleSelectionChange = (value: string) => {
    setSelectedItem(value);
  };

  // const toggleTeamInRoadmap = () => {
  //   if (team) {
  //     onTeamChange(team);
  //   }
  // };

  // const isTeamInRoadmap =
  //   team && selectedTeams.some((t) => t.name === team.name);

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (!isEmpty(team)) {
    return (
      <Layout className="flex flex-col bg-transparent">
        <Content className="flex-grow">
          <Row className="pb-7 flex justify-between items-center">
            <div className="flex items-center">
              <SearchDropdown
                placeholder="Find a Team"
                selectedItem={selectedItem}
                onSelectionChange={handleSelectionChange}
                options={selectOptions}
                showSearch
              />
            </div>
          </Row>
          <Row className="pb-4 pr-3">
            <PageHeader
              name={selectedItem}
              isViewButton={false}
              onChange={() => {}}
              // onChange={toggleTeamInRoadmap}
              // buttonName={
              //   isTeamInRoadmap ? "Remove From Roadmap" : "Add To Roadmap"
              // }
            />
          </Row>
          <Row className="pb-8">
            <TeamSection {...team} />
          </Row>
        </Content>
      </Layout>
    );
  }

  return <Empty />;
};

export default TeamScreen;
