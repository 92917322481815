import React from "react";
import theme from "assets/styles/theme";
import { ConnectWithUs } from "models/footerModel";

import FooterLogo from "./Items/FooterLogo";
import FooterLink from "./Items/FooterLink";

interface Props {
  connectWithUs: ConnectWithUs;
}

const FooterWithoutContent: React.FC<Props> = ({ connectWithUs }) => {
  return (
    <div className="flex flex-nowrap justify-between my-0 mx-10">
      <FooterLogo />
      <FooterLink
        {...connectWithUs}
        style={{ fill: theme.color.icon.social2 }}
      />
    </div>
  );
};

export default FooterWithoutContent;
