import React, { useEffect } from "react";
import { Menu, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "assets/styles/theme";

interface MenuItem {
  key: string;
  icon?: React.ReactNode;
  label: string;
  to: string;
  className?: string;
}

interface Props {
  items: MenuItem[];
}

const MainMenu: React.FC<Props> = ({ items }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e: { key: string }) => {
    navigate(e.key);
  };

  const titles: { [key: string]: string } = {};
  for (const item of items) {
    titles[item.key] = item.label;
  }

  useEffect(() => {
    const currentPath = location.pathname;
    document.title = titles[currentPath] || "Blue";
  }, [location.pathname, titles]);

  const setIconColor = (pathname: string) => (menuKey: string) =>
    pathname.startsWith(menuKey) ? theme.color.menu.icon.fill : undefined;

  return (
    <Menu
      onClick={onClick}
      selectedKeys={items
        .filter((item) => location.pathname.startsWith(item.key))
        .map((item) => item.key)}
      className="bg-transparent !border-none text-sky justify-center flex"
      items={items.map((item) => ({
        ...item,
        icon:
          item.icon &&
          React.cloneElement(item.icon as React.ReactElement, {
            fill: setIconColor(location.pathname)(item.key),
            stroke: setIconColor(location.pathname)(item.key),
          }),
      }))}
    />
  );
};

export default MainMenu;
