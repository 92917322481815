import { gql } from "@apollo/client/core";

const GET_ME = gql`
  query Me {
    me {
      username
      email
      firstName
      lastName
      id
      role {
        name
      }
    }
  }
`;

export { GET_ME };
