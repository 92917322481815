import React from "react";
import isEmpty from "lodash/isEmpty";
import { Card } from "antd";
import { WorkBreakdown } from "models/opportunityModel";
import formatNumber from "utils/formatNumber";
import { BreakDownChart } from "components/Chart";

import { AugmentationAreaSection } from ".";

interface Props extends WorkBreakdown {
  color: string;
}

const AugmentationSection: React.FC<Props> = ({
  title,
  monthlyTimeSaved,
  chartPortionValues,
  chartPercent: rawChartPercent,
  description,
  type,
  topAreas,
  color,
}) => {
  const { amount: formattedHours } = formatNumber(monthlyTimeSaved);
  const chartData = chartPortionValues.map((value) => ({ value }));
  const chartPercent = Math.floor(rawChartPercent * 10) / 10;

  const renderTopAreas = () =>
    topAreas.map((text, index) => (
      <div key={index} className="w-full">
        <AugmentationAreaSection text={text} color={color} />
      </div>
    ));

  return (
    <Card bordered>
      <p className="text-lg font-bold leading-7 pb-4">{title}</p>
      <p className="text-base font-normal leading-6 pb-4">
        {formattedHours} hour(s)/mo.
      </p>
      {!isEmpty(chartData) && (
        <BreakDownChart
          color={color}
          data={chartData}
          text={type}
          chartPercent={chartPercent}
        />
      )}
      <p className="text-base font-normal leading-6 pb-4">
        {chartPercent}% {description}
      </p>
      {!isEmpty(topAreas) && (
        <>
          <p className="text-sm font-bold leading-5 pb-4">
            {`Top areas for ${
              type === "Augmented" ? "augmentation" : "automation"
            }:`}
          </p>
          {renderTopAreas()}
        </>
      )}
    </Card>
  );
};

export default AugmentationSection;
