import React, { useState } from "react";
import { Col, Row } from "antd";
import { getOpportunity } from "services/opportunityService";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";
import Loader from "components/Loading/Loader";
import { AIOpportunity } from "models/opportunityModel";
import ErrorPage from "components/Router/ErrorPage";
import { useFetchData } from "hooks";

import TeamScreen from "./TeamScreen";
import AdoptionScreen from "./AdoptionScreen";
import TopScreen from "./TopScreen";
import RoleScreen from "./RoleScreen";
import InfoScreen from "./InfoScreen";

const Opportunity: React.FC = () => {
  const [mode, setMode] = useState("teams");
  const {
    data: opportunities,
    loading,
    error,
  } = useFetchData<AIOpportunity>(getOpportunity);

  const handleModeChange = (value: string) => {
    setMode(value);
  };

  if (loading) {
    return (
      <>
        <Loader />
        <MainLayout />
      </>
    );
  }

  return (
    <MainLayout>
      {error && <ErrorPage />}
      {opportunities ? (
        <CardLayout>
          <Row className="bg-transparent rounded-3xl">
            <InfoScreen
              totalTeams={opportunities.totalTeams}
              totalJobs={opportunities.totalJobs}
              totalTasks={opportunities.totalTasks}
              aiExposure={opportunities.aiExposure}
              annualProdGain={opportunities.annualProdGain}
              annualTimeSaved={opportunities.annualTimeSaved}
            />
          </Row>
          <BoxLayout>
            <Col
              span={19}
              className="flex flex-col flex-1 mr-4 rounded-lg shadow-gradient"
            >
              <Row>
                <TopScreen onModeChange={handleModeChange} />
              </Row>
              <Row className="flex-1">
                {mode === "teams" ? (
                  <TeamScreen teams={opportunities.teams} />
                ) : (
                  <RoleScreen roles={opportunities.roles} />
                )}
              </Row>
            </Col>
            <Col span={5} className="flex-1">
              <AdoptionScreen {...opportunities.adoption} />
            </Col>
          </BoxLayout>
        </CardLayout>
      ) : (
        <></>
      )}
    </MainLayout>
  );
};

export default Opportunity;
