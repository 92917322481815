import React, { FC, useState } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import theme from "assets/styles/theme";

export interface TabConfig {
  value: string;
  label: string;
  icon: React.ComponentType<{ fill: string }>;
}

interface Props {
  onModeChange: (value: string) => void;
  tabConfig: TabConfig[];
  defaultValue?: string;
}

const SwitchGroupButton: FC<Props> = ({
  onModeChange,
  tabConfig,
  defaultValue,
}) => {
  const [mode, setMode] = useState(defaultValue || tabConfig[0].value);

  const handleModeChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setMode(value);
    onModeChange(value);
  };

  return (
    <Radio.Group
      className="flex float-left"
      onChange={handleModeChange}
      value={mode}
      optionType="button"
      buttonStyle="solid"
    >
      {tabConfig.map((tab, index) => (
        <Radio.Button
          key={tab.value}
          value={tab.value}
          className={`h-12 flex items-center !border-none hover:text-purple-500 ${
            index === 0
              ? "!rounded-l-2xl"
              : index === tabConfig.length - 1
              ? "!rounded-r-2xl"
              : ""
          }`}
        >
          <span className="flex items-center">
            <tab.icon
              fill={
                mode === tab.value
                  ? theme.color.icon.default
                  : theme.color.icon.black
              }
            />
            <span className="pl-3 font-bold">{tab.label}</span>
          </span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default SwitchGroupButton;
