import React from "react";
import { Flex, Spin } from "antd";

const Loader: React.FC = () => (
  <Flex align="center" gap="middle">
    <Spin size="large" tip="Loading..." fullscreen={true} />
  </Flex>
);

export default Loader;
