import React from "react";
import { Form, Input } from "antd";

interface Props {
  name: string;
  description: string;
  onChange: (field: string, value: string) => void;
}

const PermissionDetail: React.FC<Props> = ({ name, description, onChange }) => {
  return (
    <Form layout="vertical">
      <Form.Item
        rules={[
          { required: true, message: "Please enter the role name" },
          { max: 255, message: "Name cannot exceed 255 characters" },
        ]}
      >
        <Input
          value={name}
          maxLength={255}
          onChange={(e) => onChange("name", e.target.value)}
          placeholder="Role name"
        />
      </Form.Item>

      <Form.Item
        rules={[
          { required: true, message: "Please enter the description" },
          { max: 500, message: "Description cannot exceed 500 characters" },
        ]}
      >
        <Input.TextArea
          value={description}
          maxLength={500}
          onChange={(e) => onChange("description", e.target.value)}
          placeholder="Description"
          rows={9}
        />
      </Form.Item>
    </Form>
  );
};

export default PermissionDetail;
