import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { updateAdoptionSlider } from "services/opportunityService";

interface Props {
  onValueChange: (value: number) => void;
  defaultValue: number;
  description?: string;
}

const AdoptionSlider: React.FC<Props> = ({
  onValueChange,
  defaultValue,
  description = "",
}) => {
  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    onValueChange(defaultValue / 100);
  }, []);

  const handleMouseUp = (size: number) => {
    updateAdoptionSlider(size);
    onValueChange(size / 100);
  };

  const onChange = (size: number) => {
    setValue(size);
  };

  return (
    <div className="adoption-slider text-black">
      <p className="text-4xl font-black leading-8 capitalize text-left pb-1">
        {value}%
      </p>
      <p className="text-base font-normal leading-6 text-left">{description}</p>
      <div className="w-full">
        <Slider
          value={value}
          onChange={onChange}
          onChangeComplete={handleMouseUp}
        />
      </div>
    </div>
  );
};

export default AdoptionSlider;
