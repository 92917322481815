import React from "react";
import { CheckOutlined } from "@ant-design/icons";

interface Tab {
  title: string;
  description: string;
  icon: React.ReactNode;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

const TabItem: React.FC<Tab> = ({
  title,
  description,
  icon,
  isActive,
  isCompleted,
  isDisabled,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`p-4 my-4 mr-4 cursor-pointer rounded-md text-center transition-all duration-300 shadow-gradient border border-gray900t2 ${
        isActive ? "bg-black text-white" : "bg-white text-black"
      } ${isDisabled ? "cursor-not-allowed opacity-50" : ""}`}
    >
      <div className="h-12 flex justify-between items-center">
        <div>{icon}</div>
        <div
          className={`w-6 h-6 flex items-center justify-center rounded-full border border-black ${
            isActive
              ? isCompleted
                ? "bg-white text-black"
                : "bg-white"
              : isCompleted
              ? "bg-black text-white"
              : "bg-white"
          }`}
        >
          {isCompleted && <CheckOutlined />}
        </div>
      </div>
      <h3 className="text-2xl font-black leading-8 text-left !text-gradient-base text-gradient-purple pb-2">
        {title}
      </h3>
      <p className="text-sm font-normal leading-5 text-left">{description}</p>
    </div>
  );
};

export default TabItem;
