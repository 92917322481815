import React, { useEffect, useState } from "react";
import { Row, Layout, Col, Input, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import useFetchData from "hooks/useFetchData";
import { fetchRoadMaps } from "services/roadMapService";
import { SearchIcon } from "components/Icon";
import { ListSection } from "components/Section";
import theme from "assets/styles/theme";
import { PaginationList } from "components/Pagination";
import { ListItem } from "models/roadMapModel";
import isEmpty from "lodash/isEmpty";
import Loader from "components/Loading/Loader";

const { Content } = Layout;

const RoadMapList: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const { data = [], loading } = useFetchData(fetchRoadMaps);
  const [filteredRoadMap, setFilteredRoadMap] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);

  const navigate = useNavigate();

  const handleOnClick = (id: string) => {
    navigate(`/adoption-roadmap/${id}`);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const filteredData = data.filter((item: ListItem) =>
        item.name.toLowerCase().includes(searchText.trim().toLowerCase())
      );
      setFilteredRoadMap(filteredData);
      setCurrentPage(1);
    }
  }, [data, searchText]);

  const paginatedData: ListItem[] = filteredRoadMap.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Layout className="flex flex-col bg-transparent mb-4 min-h-60">
      <Content className="flex-grow">
        {loading ? (
          <Loader />
        ) : (
          <>
            {/* FIXME: hide this feature will open next sprint  */}
            <Row className="pb-2 hidden">
              <Input
                placeholder="Find a roadmap"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                className="font-medium bg-white h-11 w-72"
                prefix={<SearchIcon stroke={theme.figmaColor.gray800} />}
              />
            </Row>
            {paginatedData.length > 0 ? (
              <>
                <Row className="text-lg font-bold leading-7 pb-2">
                  Roadmaps you created
                </Row>
                <Row className="pl-0" gutter={[0, 4]}>
                  {paginatedData.map((item, index) => (
                    <Col key={index} span={6}>
                      <ListSection
                        {...item}
                        onClick={() => handleOnClick(item.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            ) : (
              <Col span={24}>
                <Empty />
              </Col>
            )}
            <Row>
              <div className="flex justify-start pt-4">
                <PaginationList
                  currentPage={currentPage}
                  pageSize={pageSize}
                  total={filteredRoadMap.length}
                  onPageChange={handlePageChange}
                />
              </div>
            </Row>
          </>
        )}
      </Content>
    </Layout>
  );
};

export default RoadMapList;
