import React from "react";

interface Props {
  className?: string;
  text: string;
}

const TextSection: React.FC<Props> = ({ className, text }) => {
  return (
    <>
      <p className={className}>{text}</p>
    </>
  );
};

export default TextSection;
