import React from "react";

const InfoScreen: React.FC = () => {
  return (
    <div className="p-8 pb-0">
      <div className="text-5xl font-black leading-10 text-white pb-5">
        {/* <div className="text-4xl font-light leading-10 text-white pb-5"> */}
        Enhance Your Workforce with AI
      </div>
      <div className="text-xl font-normal leading-7 text-left text-white">
        {" "}
        Ask questions about the possibility and explore teams and roles to build
        a roadmap for adoption.
      </div>
    </div>
  );
};

export default InfoScreen;
