import { Row, Col, Image } from "antd";
import React from "react";
import logoDefaultURL from "assets/images/logo_sm.png";

interface Props {
  logoURL?: string;
}

const AuthLogo: React.FC<Props> = ({ logoURL }) => {
  return (
    <Row justify="center" className="mb-5">
      <Col>
        <Image width={64} src={logoURL} alt="Logo" preview={false} />
      </Col>
      <Col className="flex items-center justify-center pl-6">
        <h1 className="m-0 text-3xl font-extrabold leading-7">project blue</h1>
      </Col>
    </Row>
  );
};

AuthLogo.defaultProps = {
  logoURL: logoDefaultURL,
};

export default AuthLogo;
