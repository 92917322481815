import { BackToLoginButton } from "components/Button";
import React from "react";

const ErrorPage: React.FC = () => {
  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="text-center">
        <h2 className="text-red-500 pb-12 text-lg">Internal Service Error</h2>
        <BackToLoginButton isLink={false} />
      </div>
    </div>
  );
};

export default ErrorPage;
