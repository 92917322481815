import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const getAllParams = (filterParams: string[]): string[] => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paramNames = [...searchParams.keys()];

  let allParams: string[] = [];

  for (const paramName of paramNames) {
    if (isEmpty(filterParams) || filterParams.includes(paramName)) {
      allParams = [...allParams, paramName];
    }
  }

  return allParams;
};

export default getAllParams;
