import React from "react";
import { OpportunityRoles } from "models/opportunityModel";
import { Layout } from "antd";

import Table from "./RoleTable";

const { Content } = Layout;

interface Props {
  roles?: OpportunityRoles[];
}

const RoleScreen: React.FC<Props> = ({ roles }) => {
  return (
    <Layout className="flex flex-col bg-white">
      <Content className="flex-grow">
        {roles && <Table roles={roles} />}
      </Content>
    </Layout>
  );
};

export default RoleScreen;
