import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const GoogleIcon: React.FC<IconProps> = ({
  fill = theme.color.icon.social,
  to = "",
}) => {
  const icon = (
    <span className="ant-menu-item-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M24 11.9999C24.0001 14.8254 23.0032 17.5604 21.1849 19.7231C19.3665 21.8858 16.8433 23.3374 14.0597 23.8224C11.2761 24.3074 8.41064 23.7946 5.96801 22.3743C3.52539 20.954 1.66229 18.7173 0.706855 16.0582C-0.248576 13.3991 -0.235048 10.4881 0.745054 7.83804C1.72516 5.18793 3.60897 2.96867 6.06468 1.57113C8.5204 0.17359 11.3905 -0.312578 14.1695 0.198253C16.9485 0.709084 19.4581 2.18415 21.2563 4.36364C21.3455 4.46409 21.4136 4.5815 21.4565 4.70885C21.4994 4.83621 21.5162 4.97091 21.5058 5.10489C21.4955 5.23888 21.4582 5.36941 21.3963 5.48868C21.3344 5.60795 21.2491 5.71351 21.1455 5.79907C21.0418 5.88463 20.922 5.94843 20.7932 5.98664C20.6643 6.02486 20.5291 6.03672 20.3956 6.02151C20.2621 6.0063 20.133 5.96434 20.0161 5.89813C19.8991 5.83191 19.7967 5.74281 19.715 5.63614C18.2442 3.85279 16.201 2.63406 13.933 2.18728C11.665 1.7405 9.31221 2.09327 7.27495 3.18557C5.23769 4.27788 3.64178 6.04225 2.7587 8.17853C1.87562 10.3148 1.75992 12.6911 2.43128 14.903C3.10265 17.115 4.51961 19.0261 6.44111 20.3111C8.36261 21.5962 10.67 22.1759 12.9707 21.9515C15.2714 21.7272 17.4233 20.7127 19.0604 19.0807C20.6975 17.4487 21.7186 15.2999 21.95 12.9999H12C11.7348 12.9999 11.4804 12.8945 11.2929 12.707C11.1054 12.5195 11 12.2651 11 11.9999C11 11.7347 11.1054 11.4803 11.2929 11.2928C11.4804 11.1052 11.7348 10.9999 12 10.9999H23C23.2652 10.9999 23.5196 11.1052 23.7071 11.2928C23.8946 11.4803 24 11.7347 24 11.9999Z"
          fill={fill}
        />
      </svg>
    </span>
  );

  return to ? (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {icon}
    </a>
  ) : (
    icon
  );
};

export default GoogleIcon;
