import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Register from "containers/Authentication/GetStarted";
import ForgotPassword from "containers/Authentication/ForgotPassword";
import Login from "containers/Authentication/Login";
import ResetPassword from "containers/Authentication/ResetPassword";
import UploadPage from "containers/Admin/ImportOrgPage/UploadPage";
import { USER_ROLE } from "common/constants";
import ScrollToTop from "components/ScrollToTop";
import TenantList from "containers/Admin/TenantPage/TenantList/TenantList";
import TenantDetail from "containers/Admin/TenantPage/TenantDetail/TenantDetail";
import RoadmapDetail from "containers/Roadmap/RoadMapDetail";
import AdminUserList from "containers/Admin/AdminUserPage/AdminUserList";
import Roadmap from "containers/Roadmap/RoadMap";
import Opportunity from "containers/Opportunity/Opportunity";
import Workforce from "containers/Workforce/Workforce";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import PermissionDenied from "./PermissionDenied";
import PageNotFound from "./PageNotFound";

const { ADMIN, USER } = USER_ROLE;

const Router: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ProtectedRoutes roleRequired={[USER]} />}>
          <Route path="/" element={<Navigate replace to="ai-opportunity" />} />
          <Route path="ai-opportunity" element={<Opportunity />} />
          <Route path="ai-workforce-potential" element={<Workforce />} />
          <Route path="adoption-roadmap" element={<Roadmap />} />
          <Route path="adoption-roadmap/:id" element={<RoadmapDetail />} />
        </Route>
        <Route
          path="/admin"
          element={<ProtectedRoutes roleRequired={[ADMIN]} />}
        >
          <Route
            path="/admin"
            element={<Navigate replace to="import-organization-file" />}
          />
          <Route path="import-organization-file" element={<UploadPage />} />
          <Route path="tenant-management" element={<TenantList />} />
          <Route path="tenant-management/:id" element={<TenantDetail />} />
          <Route path="admin-user-management" element={<AdminUserList />} />
        </Route>

        {/** Public Routes */}
        {/** Wrap all Route under PublicRoutes element */}
        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/get-started" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/** Permission denied route */}
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
