import React from "react";
import { Card } from "antd";
import { ProductivityGainByLevel } from "models/workforceModel";
import formatNumber from "utils/formatNumber";
import isEmpty from "lodash/isEmpty";

import { AugmentationAreaSection } from ".";

interface Props extends ProductivityGainByLevel {
  label: string;
  color?: string;
}

const GainSection: React.FC<Props> = ({
  title,
  annualProdGain,
  annualTimeSaved,
  label,
  activities,
  color = "",
}) => {
  const { amount: formattedHours, currency: hourCurrency } =
    formatNumber(annualTimeSaved);
  const { amount: formattedCost, currency: costCurrency } =
    formatNumber(annualProdGain);

  return (
    <Card bordered>
      <div className="text-lg font-bold leading-7 pb-4 w-full">{title}</div>
      <div className="pt-2">
        <div
          className={`text-4xl font-black capitalize leading-8 !text-gradient-base ${color}`}
        >
          ${formattedCost} {costCurrency}
        </div>
        <div className="pt-2">
          {" "}
          {formattedHours}
          {hourCurrency} hours / yr.
        </div>
      </div>
      <div className="pt-6">
        {!isEmpty(activities) && (
          <div className="text-sm font-bold leading-5 pb-4 w-full">{label}</div>
        )}
        {activities.map((text, index) => (
          <div key={index} className="w-full">
            <AugmentationAreaSection text={text} color={color} />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default GainSection;
