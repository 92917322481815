import React, { useEffect, useState } from "react";
import { Button, message, Modal } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import {
  CompassIcon,
  CubeFocusIcon,
  GraphIcon,
  UserFocusIcon,
} from "components/Icon";
import theme from "assets/styles/theme";
import { InlineInput } from "components/Input";
import { useNavigate } from "react-router-dom";
import { upsertRoadMap } from "services/roadMapService";
import { RoadMapPayLoad } from "models/roadMapModel";

import Tab1Content from "./Tab1Content";
import Tab2Content from "./Tab2Content";
import Tab3Content from "./Tab3Content";
import Tab4Content from "./Tab4Content";
import TabItem from "./TabItem";

interface Props {
  completedSteps: string[];
  name: string;
  pathId: string;
  type: string;
}

const TabGroup: React.FC<Props> = ({
  completedSteps = [],
  name: defaultName = "",
  pathId,
  type,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [completedTabs, setCompletedTabs] = useState<string[]>(completedSteps);
  const [name, setName] = useState(defaultName);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [targetTab, setTargetTab] = useState<string | null>("");
  const [selectedCardToToggle, setSelectedCardToToggle] = useState<string>("");
  const [selectedCardId, setSelectedCardId] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    setCompletedTabs(completedSteps);
  }, [completedSteps]);

  const onTabComplete = (key: string) => {
    setCompletedTabs([...completedTabs, key]);
    const nextKey = (Number.parseInt(key) + 1).toString();
    setActiveTab(nextKey);
    setSelectedCardToToggle("");
  };

  const onLastTabComplete = (key: string) => {
    const nextKey = (Number.parseInt(key) + 1).toString();
    setCompletedTabs([...completedTabs, key, nextKey]);
    setActiveTab(nextKey);
    setSelectedCardToToggle("");
  };

  const handleInputChange = async (value: string) => {
    const str = value.trim();
    if (str.length <= 0 || str.length > 255) {
      message.error("The input must be between 1 and 255 characters.");
    } else {
      try {
        const payload: RoadMapPayLoad = {
          id: pathId,
          name: str,
          type,
        };
        await upsertRoadMap(payload);
        message.success("Roadmap name successfully updated.");
        setName(str);
      } catch {
        message.error("Failed to update roadmap name. Please try again.");
      }
    }
  };

  const isTabDisabled = (key: string) => {
    return (
      !completedTabs.includes((Number.parseInt(key) - 1).toString()) &&
      key !== "1"
    );
  };

  const isTabCompleted = (key: string) => completedTabs.includes(key);

  const backToRoadMap = () => {
    navigate("/adoption-roadmap?list");
  };

  const handleCardClick = (key: string, id: string) => {
    if (isTabCompleted(key)) {
      setTargetTab(key);
      setIsModalVisible(true);
      setSelectedCardId(id);
    }
  };

  const handleModalOk = () => {
    if (targetTab) {
      const newCompletedTabs = completedTabs.filter(
        (tabKey) => Number(tabKey) < Number(targetTab)
      );
      setCompletedTabs(newCompletedTabs);
      setTargetTab("");
      setIsModalVisible(false);
      setSelectedCardToToggle(selectedCardId);
    }
  };

  const handleModalCancel = () => {
    setTargetTab("");
    setIsModalVisible(false);
  };

  const tabs = [
    {
      key: "1",
      title: "Teams",
      description:
        "Choose the teams you want to augment through your selected products.",
      icon: (
        <CubeFocusIcon
          fill={
            activeTab === "1"
              ? theme.color.icon.default
              : theme.color.icon.black
          }
          width={32}
          height={32}
        />
      ),
      content: (
        <Tab1Content
          pathId={pathId}
          onTabContentChange={onTabComplete}
          isTabCompleted={isTabCompleted("1")}
          onCardClick={handleCardClick}
          selectedCardToToggle={selectedCardToToggle}
        />
      ),
    },
    {
      key: "2",
      title: "Tasks",
      description:
        "Explore and select the tasks from your teams to focus on augmenting.",
      icon: (
        <GraphIcon
          fill={
            activeTab === "2"
              ? theme.color.icon.default
              : theme.color.icon.black
          }
          width={32}
          height={32}
        />
      ),
      content: (
        <Tab2Content
          pathId={pathId}
          onTabContentChange={onTabComplete}
          onCardClick={handleCardClick}
          isTabCompleted={isTabCompleted("2")}
          selectedCardToToggle={selectedCardToToggle}
        />
      ),
    },
    {
      key: "3",
      title: "Roles",
      description:
        "Select the roles to include in this roadmap that will benefit from the selected activities.",
      icon: (
        <UserFocusIcon
          fill={
            activeTab === "3"
              ? theme.color.icon.default
              : theme.color.icon.black
          }
          width={32}
          height={32}
        />
      ),
      content: (
        <Tab3Content onTabContentChange={onLastTabComplete} pathId={pathId} />
      ),
    },
    {
      key: "4",
      title: "Summary",
      description:
        "Get a summary of the total hour potential and export your roadmap.",
      icon: (
        <CompassIcon
          fill={
            activeTab === "4"
              ? theme.color.icon.default
              : theme.color.icon.black
          }
          width={32}
          height={32}
        />
      ),
      content: (
        <Tab4Content onTabContentChange={backToRoadMap} pathId={pathId} />
      ),
    },
  ];

  return (
    <div className="container mx-auto pl-7 pt-6">
      <Button icon={<LeftOutlined />} iconPosition="start" className="p-4 h-12">
        <span
          className="text-base font-semibold leading-6"
          onClick={backToRoadMap}
        >
          Back to Roadmaps
        </span>
      </Button>
      <div className="h-20 flex justify-start items-center">
        <InlineInput
          className="w-full text-3xl font-extrabold text-left !text-gradient-base text-gradient-purpleblue pr-3"
          value={name}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex justify-between">
        {tabs.map((tab, index) => (
          <TabItem
            key={index}
            title={tab.title}
            description={tab.description}
            icon={tab.icon}
            isActive={activeTab === tab.key}
            isCompleted={isTabCompleted(tab.key)}
            isDisabled={isTabDisabled(tab.key)}
            onClick={() => !isTabDisabled(tab.key) && setActiveTab(tab.key)}
          />
        ))}
      </div>

      <div className="mt-6 p-4 pl-0">
        {tabs.find((tab) => tab.key === activeTab)?.content}
      </div>

      <Modal
        title="Confirm Change"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
        centered
        maskClosable={false}
      >
        <p>
          Selecting/Unselecting this step with reset the following step(s), do
          you wish to continue?
        </p>
      </Modal>
    </div>
  );
};

export default TabGroup;
