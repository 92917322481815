import React, { useState } from "react";
import { Empty, Layout } from "antd";
import { getWorkforceRoles } from "services/workforceService";
import { ExploreRole, TeamRole } from "models/workforceModel";
import isEmpty from "lodash/isEmpty";
import Loader from "components/Loading/Loader";
import { useQuery, useFetchData } from "hooks";
import { useNavigate } from "react-router-dom";

import RoleTable from "./RoleTable";
import RoleDetail from "./RoleDetail";

const { Content } = Layout;

interface Props {
  onRoleChange: (value: ExploreRole) => void;
  selectedRoles?: ExploreRole[];
}

const RoleScreen: React.FC<Props> = ({ onRoleChange, selectedRoles = [] }) => {
  const isRole = !!useQuery().get("role");
  const [isViewRole, setIsViewRole] = useState(isRole);
  const navigate = useNavigate();

  const {
    data: roles,
    loading,
    error,
  } = useFetchData<TeamRole[]>(getWorkforceRoles);

  const handleTableChange = (key: string) => {
    setIsViewRole(true);
    navigate(`/ai-workforce-potential?role=${encodeURIComponent(key)}`);
  };

  const handleDetailChange = () => {
    setIsViewRole(false);
    navigate(`/ai-workforce-potential?role=`);
  };

  const handleRoleChange = (role: ExploreRole) => {
    onRoleChange(role);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!isEmpty(roles)) {
    return (
      <Layout className="flex flex-col bg-transparent">
        <Content className="flex-grow">
          {isViewRole ? (
            <RoleDetail
              onChange={handleDetailChange}
              onRoleChange={handleRoleChange}
              selectedRoles={selectedRoles}
            />
          ) : (
            <RoleTable
              roles={roles as TeamRole[]}
              enableSearch={true}
              onRowChange={handleTableChange}
            />
          )}
        </Content>
      </Layout>
    );
  }

  return <Empty />;
};

export default RoleScreen;
