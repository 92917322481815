import { gql } from "@apollo/client/core";

const GET_TEAM_LIST = gql`
  query GetTeamNamesnOrg {
    getTeamNamesInOrg
  }
`;

const GET_ROLE_LIST = gql`
  query ExploreRoles {
    exploreRoles {
      roles {
        title
        totalTeams
        totalEmployees
        annualProdGain
        annualTimeSaved
      }
    }
  }
`;

const GET_TEAM_BY_NAME = gql`
  query ExploreTeam($teamName: String!) {
    exploreTeam(teamName: $teamName) {
      name
      monthlyTimeSaved
      annualProdGain
      taskImpacted
      totalTasks
      totalRoles
      totalAugmentedRoles
      aiOpportunities {
        title
        type
        monthlyTimeSaved
        description
        chartPercent
        chartPortionValues
        topAreas
      }
      productivityGainByLevels {
        title
        annualProdGain
        annualTimeSaved
        activities
      }
      impactedTasks {
        title
        description
        totalEmployees
        monthlyTimeSaved
      }
      roles {
        title
        totalEmployees
        annualProdGain
        annualTimeSaved
      }
    }
  }
`;
const GET_ROLE_BY_NAME = gql`
  query ExploreRole($roleTitle: String!) {
    exploreRole(roleTitle: $roleTitle) {
      name
      monthlyTimeSaved
      annualProdGain
      taskImpacted
      totalEmployees
      aiOpportunities {
        title
        type
        monthlyTimeSaved
        description
        chartPercent
        chartPortionValues
        topAreas
      }
      productivityGainByLevels {
        title
        annualProdGain
        annualTimeSaved
        activities
      }
      impactedTasks {
        title
        description
        totalEmployees
        totalTeams
        monthlyTimeSaved
      }
    }
  }
`;
export { GET_TEAM_LIST, GET_ROLE_LIST, GET_TEAM_BY_NAME, GET_ROLE_BY_NAME };
