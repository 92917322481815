import React from "react";
import { Modal, Button, Input, Form } from "antd";

interface CreateTenantModalProps {
  visible: boolean;
  onCreate: (values: string) => void;
  onCancel: () => void;
}

const CreateTenantModal: React.FC<CreateTenantModalProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      open={visible}
      title="Create Tenant"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate(values);
              })
              .catch(() => {});
          }}
        >
          Create
        </Button>,
      ]}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" name="tenant_form">
        <Form.Item
          name="tenantName"
          label="Tenant Name"
          rules={[{ required: true, message: "Please input the tenant name!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTenantModal;
