import { gql } from "@apollo/client/core";

const LOGIN_MUTATION = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
    }
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation Forgot($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation Reset(
    $code: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      user {
        id
      }
    }
  }
`;
const REGISTER_MUTATION = gql`
  mutation Register($registerInput: UserRegisterInput!) {
    register(input: $registerInput) {
      jwt
      user {
        email
        confirmed
      }
    }
  }
`;

const VALIDATE_INVITATION = gql`
  query ValidateUserInvitation($invitationFilters: InvitationFiltersInput!) {
    isUserInvitationValid(filters: $invitationFilters)
  }
`;

const VALIDATE_RESET_PWD = gql`
  query CanUserResetPassword(
    $resetPasswordFiltersInput: ResetPasswordFiltersInput!
  ) {
    canUserResetPassword(filters: $resetPasswordFiltersInput)
  }
`;

export {
  LOGIN_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  RESET_PASSWORD_MUTATION,
  REGISTER_MUTATION,
  VALIDATE_INVITATION,
  VALIDATE_RESET_PWD,
};
