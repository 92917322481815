import React, { useState } from "react";
import { Col, Input, Row } from "antd";
import Table from "components/Table";
import { ColumnsType } from "antd/es/table";

import DetailActions from "../DetailActions";

interface User {
  id: number;
  email: string;
  roles: string;
  status: string;
}

const initialData: User[] = [
  { id: 1, email: "jone@gmail.com", roles: "Admin", status: "Active" },
  {
    id: 2,
    email: "wibuuu@gmail.com2",
    roles: "Employer",
    status: "Invited",
  },
  { id: 3, email: "phongqnguyen@gmail.com3", roles: "Admin", status: "Active" },
  {
    id: 4,
    email: "William@gmail.com",
    roles: "Admin",
    status: "Inactive",
  },
  {
    id: 5,
    email: "pikapikia@gmail.com",
    roles: "Employer",
    status: "Active",
  },
];

const handleUpdate = () => {
  console.log("Edit clicked");
};

const handleResendInvite = () => {
  console.log("Re-send invite clicked");
};

const columns: ColumnsType = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_: string, record: User) => (
      <div
        className="text-base font-normal leading-6 truncate w-auto"
        title={record.email}
      >
        {record.email}
      </div>
    ),
  },
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
    render: (_: string, record: User) => (
      <div
        className="text-base font-normal leading-6 truncate w-auto"
        title={record.roles}
      >
        {record.roles}
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_: string, record: User) => (
      <div
        className="text-base font-normal leading-6 truncate w-auto"
        title={record.status}
      >
        {record.status}
      </div>
    ),
  },
  {
    title: "Actions",
    key: "actions",
    width: 120,
    render: (_, record: { status: string }) => (
      <DetailActions
        onUpdate={handleUpdate}
        onResendInvite={
          record.status === "Invited" ? handleResendInvite : undefined
        }
      />
    ),
  },
];

const UserList: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  const handleSearch = (value: string) => {
    setSearchText(value);
    setFilteredData(
      initialData.filter((item) =>
        item.email.toLowerCase().includes(value.trim().toLowerCase())
      )
    );
  };

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Input
            placeholder="Search Users"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="font-medium float-left"
            style={{ width: 300, height: 44 }}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={filteredData}
            pagination={false}
            scroll={{ y: 400, x: 800 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserList;
