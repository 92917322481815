import React from "react";
import { CheckOutlined } from "@ant-design/icons";

interface CheckboxProps {
  isChecked: boolean;
  onToggle?: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ isChecked, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      data-testid="checkbox"
      className={`w-6 h-6 flex items-center justify-center rounded-full border border-black cursor-pointer transition-all duration-300 ${
        isChecked ? "bg-black text-white" : "bg-white text-black"
      }`}
    >
      {isChecked && <CheckOutlined />}
    </div>
  );
};

export default Checkbox;
