import React from "react";
import { Image } from "antd";
import { Link } from "react-router-dom";
import logoURL from "assets/images/logo.png";

const Logo: React.FC = () => {
  return (
    <Link to="/" className="flex items-center">
      <Image width={32} src={logoURL} alt="Logo" preview={false} />
      <div className="text-white pl-5 font-black">Blue</div>
    </Link>
  );
};

export default Logo;
