import React, { useEffect } from "react";
import { Modal, Button, Input } from "antd";

interface Props {
  visible: boolean;
  tenants: string[];
  onClose: () => void;
}

const TenantListModal: React.FC<Props> = ({ visible, tenants, onClose }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    if (visible) {
      setSearchTerm("");
    }
  }, [visible]);

  const filteredTenants = tenants.filter((tenant) =>
    tenant.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      title="Tenant List"
      open={visible}
      onCancel={onClose}
      centered
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Input
        placeholder="Find a tenant"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-2 mt-2"
      />
      <ul className="h-44 overflow-y-auto p-0 m-0 list-none">
        {filteredTenants.map((tenant) => (
          <li key={tenant} className="py-1 pl-2">
            {" "}
            {tenant}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default TenantListModal;
