import React from "react";
import { Card } from "antd";
import { OpportunityTeam } from "models/opportunityModel";
import Carousel from "components/Carousel";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components/Header";
import { TeamIcon } from "components/Icon";

import TeamContent from "./TeamContent";

interface Props {
  teams: OpportunityTeam[];
}

const TeamScreen: React.FC<Props> = ({ teams = [] }) => {
  const navigate = useNavigate();

  const handleOnChange = (value: string) => {
    navigate(`/ai-workforce-potential?team=${encodeURIComponent(value)}`);
  };

  return (
    <Card className="h-full w-full p-0 !shadow-none !border-none rounded-tl-none rounded-tr-none relative">
      <Carousel>
        {teams.map((team, index) => (
          <div key={index}>
            <div className="pt-8 pb-8">
              <PageHeader
                name={team.name as string}
                onChange={handleOnChange}
                buttonName="View Team"
                buttonIcon={<TeamIcon />}
              />
            </div>
            <br />
            <TeamContent {...team} />
          </div>
        ))}
      </Carousel>
    </Card>
  );
};

export default TeamScreen;
