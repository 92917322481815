import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { getUserToken } from "utils/storage";
import {
  register,
  resetPassword,
  validateInvitation,
  validateResetPwd,
} from "services/authService";

import error from "../utils/error";

interface AuthState {
  token?: string | null;
  error?: string;
  loading: boolean;
  isReset: boolean;
  isRegister: boolean;
  isInvitationValid: boolean;
  isResetValid: boolean;
}

function getResetPwdInfo(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
      state.isReset = true;
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.error);
    });
}

function getRegisterInfo(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(register.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(register.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
      state.isRegister = true;
    })
    .addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.error = error(action.error);
    });
}

function getInvitationValidInfo(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(validateInvitation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(validateInvitation.fulfilled, (state, action) => {
      state.loading = false;
      state.isInvitationValid = action.payload;
      state.error = undefined;
    })
    .addCase(validateInvitation.rejected, (state, action) => {
      state.loading = false;
      state.isInvitationValid = false;
      state.error = error(action.error);
    });
}

function getResetValidInfo(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(validateResetPwd.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    })
    .addCase(validateResetPwd.fulfilled, (state, action) => {
      state.loading = false;
      state.isResetValid = action.payload;
      state.error = undefined;
    })
    .addCase(validateResetPwd.rejected, (state, action) => {
      state.loading = false;
      state.isResetValid = false;
      state.error = error(action.error);
    });
}

const initialState: AuthState = {
  token: getUserToken(),
  error: undefined,
  loading: false,
  isReset: false,
  isRegister: false,
  isInvitationValid: true,
  isResetValid: true,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutSuccess() {
      document.title = "Project blue";
      localStorage.removeItem("userToken");
      localStorage.removeItem("tenantId");
      localStorage.removeItem("taskImpactSlider");
      sessionStorage.removeItem("userInfo");
      return {
        ...initialState,
        token: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    getResetPwdInfo(builder);
    getRegisterInfo(builder);
    getInvitationValidInfo(builder);
    getResetValidInfo(builder);
  },
});

export const { logoutSuccess } = slice.actions;
export default slice.reducer;
