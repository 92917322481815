import React from "react";
import { Form } from "antd";
import { BackToLoginButton } from "components/Button";
import { ErrorSpace } from "components/Alert";

interface Props {
  error?: string;
}

const ErrorForm: React.FC<Props> = ({ error }) => {
  return (
    <div className="max-w-[360px] mx-auto p-4">
      {error && (
        <div className="pb-2">
          <ErrorSpace message={error} />
        </div>
      )}
      <Form.Item className="w-full py-2 h-0">
        <BackToLoginButton />
      </Form.Item>
    </div>
  );
};

export default ErrorForm;
