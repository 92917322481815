import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import theme from "assets/styles/theme";
import { COLORS } from "common/constants";
import { ChartItem } from "models/opportunityModel";

interface Props {
  color: string;
  data: ChartItem[];
  text: string;
  chartPercent: number;
}

interface LabelProps {
  color: string;
  text: string;
  value: number;
}

const Label: React.FC<LabelProps> = ({ color, text, value }) => (
  <div className="flex flex-col items-start">
    <p
      className={`text-4xl font-black capitalize leading-8 !text-gradient-base ${color} pb-2`}
    >
      {value}%
    </p>
    <p className="text-base font-normal leading-6 text-black">{text}</p>
  </div>
);

const getCellColors = (color: string): string[] => {
  const cellColor = theme.figmaColor;

  switch (color) {
    case COLORS.PURPLE: {
      return [cellColor.purple900, cellColor.purple500, cellColor.gray75];
    }
    case COLORS.SKY: {
      return [cellColor.sky500, cellColor.sky300, cellColor.gray75];
    }
    default: {
      return [cellColor.space1100, cellColor.space500, cellColor.gray75];
    }
  }
};

const BreakDownChart: React.FC<Props> = ({
  color,
  text,
  data,
  chartPercent,
}) => {
  const cellColors = getCellColors(color);

  return (
    <div className="flex justify-center items-center space-x-4 bg-white">
      <PieChart width={150} height={150}>
        <Pie
          data={data}
          innerRadius={22}
          outerRadius={48}
          paddingAngle={1}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={cellColors[index % cellColors.length]}
            />
          ))}
        </Pie>
      </PieChart>
      <Label color={color} text={text} value={chartPercent} />
    </div>
  );
};

export default BreakDownChart;
