import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { BackToLoginButton } from "components/Button";

interface Props {
  email: string;
}

const ForgotSuccess: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  return (
    <div className="max-w-[360px] mx-auto p-4">
      <p className="text-center text-black-500 mb-2 text-[14px] font-normal">
        {t("authFlow.forgotSuccess.msg1")}
      </p>
      <p className="text-center text-sky mb-2 text-[14px]">{email}</p>
      <p className="text-center text-black-500 mb-2 text-[14px] font-normal">
        {t("authFlow.forgotSuccess.msg2")}
      </p>
      <Form.Item className="w-full py-2">
        <BackToLoginButton />
      </Form.Item>
    </div>
  );
};

export default ForgotSuccess;
