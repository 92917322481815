import React from "react";
import { Card, Col, Row } from "antd";
import { RoadMapIcon } from "components/Icon";
import { ExploreRole, ExploreTeam } from "models/workforceModel";

interface Props {
  teams?: ExploreTeam[];
  roles?: ExploreRole[];
}

const RoadmapScreen: React.FC<Props> = () => {
  return (
    <Card className="h-full !shadow-none" bordered={false}>
      <Row gutter={[16, 16]} className="flex flex-col h-full">
        <Col span={24}>
          <RoadMapIcon />
        </Col>
        <Col span={24} className="pb-6">
          <p className="text-2xl font-light leading-8 w-full">
            Your AI Roadmap
          </p>
        </Col>
        {/* FIXME: Waiting the data from BE side, comment out these code 

        <Col span={24} className="pb-4">
          <p className="text-base font-normal leading-6">You’ve selected:</p>
          <div>
            <CountButton count={teams.length} label="Teams" />
            <CountButton count={roles.length} label="Roles" />
          </div>
        </Col>
        <Col span={24} className="pb-4">
          <StatisticSection
            value={37_869}
            label="Hours / Month"
            percentage="14%"
          />
        </Col>
        <Col span={24} className="pb-4">
          <StatisticSection value={784} label="Employees" percentage="21%" />
        </Col>
        <Col span={24} className="pb-4">
          <StatisticSection value={87} label="Activities" percentage="11%" />
        </Col> */}
        <Col className="flex items-center justify-center h-60 bg-gray75">
          <div className="text-5xl text-gray400 font-black text-center">
            Coming soon{" "}
          </div>
        </Col>
        {/* <Col className="pb-4 flex items-center justify-center">
          <Button
            iconPosition="start"
            icon={<MapPinIcon fill={theme.figmaColor.sky} />}
            className="bp-gray75 text-sm font-bold leading-5 h-12"
            href="/adoption-roadmap"
          >
            Open Roadmap
          </Button>
        </Col> */}
      </Row>
    </Card>
  );
};

export default RoadmapScreen;
