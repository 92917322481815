import React from "react";
import { Layout } from "antd";
import { USER_ROLE } from "common/constants";
import { AvatarMenu, MainMenu } from "components/Menu";
import Logo from "components/Logo";
import {
  ApertureIcon,
  AtomIcon,
  UploadIcon,
  UserCircleIcon,
} from "components/Icon";
import theme from "assets/styles/theme";

const { Header: AntdHeader } = Layout;

const { USER } = USER_ROLE;
const menuItemStyle = "!flex justify-center !w-auto";

interface MenuItem {
  key: string;
  icon?: React.ReactNode;
  label: string;
  to: string;
  className?: string;
}

interface HeaderProps {
  role?: string;
}

const Header: React.FC<HeaderProps> = ({ role }) => {
  const userMenuItems: MenuItem[] = [
    {
      key: "/ai-opportunity",
      icon: <ApertureIcon stroke={theme.color.menu.icon.fill} />,
      label: "AI Opportunity",
      to: "/ai-opportunity",
      className: menuItemStyle,
    },
    {
      key: "/ai-workforce-potential",
      icon: <UserCircleIcon fill={theme.color.menu.icon.fill} />,
      label: "AI Workforce Potential",
      to: "/ai-workforce-potential",
      className: menuItemStyle,
    },
    {
      key: "/adoption-roadmap",
      icon: <AtomIcon fill={theme.color.menu.icon.fill} />,
      label: "Adoption Roadmap",
      to: "/adoption-roadmap",
      className: menuItemStyle,
    },
  ];

  const adminMenuItems: MenuItem[] = [
    {
      key: "/admin/tenant-management",
      icon: <ApertureIcon stroke={theme.color.menu.icon.fill} />,
      label: "Tenant Management",
      to: "/admin/tenant-management",
      className: menuItemStyle,
    },
    {
      key: "/admin/import-organization-file",
      icon: <UploadIcon stroke={theme.color.menu.icon.fill} width={25} />,
      label: "Import Organization File",
      to: "/admin/import-organization-file",
      className: menuItemStyle,
    },
    {
      key: "/admin/admin-user-management",
      icon: <UserCircleIcon fill={theme.color.menu.icon.fill} />,
      label: "Admin User Management",
      to: "/admin/admin-user-management",
      className: menuItemStyle,
    },
  ];

  const menuItems = role === USER ? userMenuItems : adminMenuItems;

  return (
    <AntdHeader className="flex justify-between items-center top-0 w-full z-50 bg-transparent border-none">
      <Logo />
      <MainMenu items={menuItems} />
      <AvatarMenu />
    </AntdHeader>
  );
};

export default Header;
