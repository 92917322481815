import { COLORS } from "common/constants";
import React from "react";

interface Props {
  text: string;
  color?: string;
}

const AugmentationAreaSection: React.FC<Props> = ({ text, color }) => {
  const bgColor =
    color === COLORS.PURPLE
      ? "bg-purple900"
      : color === COLORS.SKY
      ? "bg-sky500"
      : "bg-space1100";

  return (
    <div className="inline-flex items-center bg-gray75 p-2 rounded-lg mb-2">
      <div className={`flex-shrink-0 rounded-full w-4 h-4 gap-0 ${bgColor}`} />
      <div className="text-sm font-normal pl-2">{text}</div>
    </div>
  );
};

export default AugmentationAreaSection;
