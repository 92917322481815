import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  useSelector as useRootSelector,
  useDispatch as useRootDispatch,
  TypedUseSelectorHook,
} from "react-redux";

import { reducer } from "./reducer";

const store = configureStore({
  reducer,
});

export const useDispatch = () => useRootDispatch<ReduxDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useRootSelector;

export type ReduxStore = typeof store;
export type ReduxDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action
>;

/* Types */
export default store;
