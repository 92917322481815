import React from "react";
import { Row, Button, Layout, Empty } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useQuery, useFetchData } from "hooks";
import Loader from "components/Loading/Loader";
import { getWorkforceRoleByName } from "services/workforceService";
import { ExploreRole } from "models/workforceModel";
import { PageHeader } from "components/Header";

import RoleSection from "./RoleSection";

const { Content } = Layout;

interface Props {
  onChange: (value: string) => void;
  onRoleChange?: (role: ExploreRole) => void;
  selectedRoles?: ExploreRole[];
}

const RoleDetail: React.FC<Props> = ({ onChange }) => {
  const roleName = useQuery().get("role") || "";

  const {
    data: role,
    loading,
    error,
  } = useFetchData<ExploreRole | undefined>(
    () => getWorkforceRoleByName(roleName),
    (data) => data
  );

  const handleBackClick = () => {
    onChange("..");
  };

  if (loading) return <Loader />;
  if (error) return <div className="text-red-500">{error}</div>;

  return role ? (
    <Layout className="flex flex-col bg-transparent">
      <Content className="flex-grow">
        <Row className="flex justify-between pb-8">
          <Button
            type="primary"
            icon={<LeftOutlined />}
            onClick={handleBackClick}
            className="pl-4"
          >
            Back to Roles
          </Button>
        </Row>
        <Row className="pb-4 pr-4">
          <PageHeader
            name={role.name}
            isViewButton={false}
            onChange={() => {}}
          />
        </Row>
        <Row className="pb-8">
          <RoleSection {...role} />
        </Row>
      </Content>
    </Layout>
  ) : (
    <Empty />
  );
};

export default RoleDetail;
