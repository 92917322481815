import React, { useState } from "react";
import { Input } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { PencilIcon } from "components/Icon";
import theme from "assets/styles/theme";

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  className: string;
}

const InlineInput: React.FC<Props> = ({ value, onChange, className }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleSave = () => {
    onChange(inputValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInputValue(value);
    setIsEditing(false);
  };

  return (
    <div className="inline-flex items-center truncate">
      {isEditing ? (
        <>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={className}
            autoFocus
          />
          <button
            onClick={handleSave}
            className="ml-2 text-green-500 hover:text-green-600 pr-1"
          >
            <CheckCircleOutlined className="text-2xl" />
          </button>
          <button
            onClick={handleCancel}
            className="ml-1 text-red-500 hover:text-red-600"
          >
            <CloseCircleOutlined className="text-2xl" />
          </button>
        </>
      ) : (
        <div
          className="inline-flex items-center cursor-pointer"
          onClick={() => setIsEditing(true)}
        >
          <span className={className}>{value}</span>
          <PencilIcon fill={theme.color.icon.black} width={32} height={32} />
        </div>
      )}
    </div>
  );
};

export default InlineInput;
