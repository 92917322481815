import React, { useEffect } from "react";
import { Col, Empty, Row, message } from "antd";
import { useEntityData, useTabState } from "hooks";
import {
  fetchAllRoles,
  fetchSelectedRoles,
  upsertRoadMap,
} from "services/roadMapService";
import { RoadMapPayLoad, RoadMapRole } from "models/roadMapModel";
import FilterOptions from "components/FilterOptions";
import { ClockCountIcon } from "components/Icon";
import theme from "assets/styles/theme";
import { PageSizeSelect, PaginationList } from "components/Pagination";
import isEmpty from "lodash/isEmpty";
import Checkbox from "components/Checkbox";
import { RowSection } from "components/Section";
import Table from "components/Table";
import Loader from "components/Loading/Loader";

import CardView from "../CardView";

import TabHeader from "./TabHeader";
import ViewSwitcher from "./ViewSwitcher";

interface Props {
  onTabContentChange: (key: string) => void;
  isTabCompleted?: boolean;
  onCardClick?: () => void;
  pathId: string;
}

const Tab3Content: React.FC<Props> = ({ onTabContentChange, pathId }) => {
  const {
    allEntities: allRoles,
    selectedEntities: selectedRoles,
    loading,
  } = useEntityData<RoadMapRole>(
    () => fetchAllRoles(pathId as string),
    () => fetchSelectedRoles(pathId as string)
  );

  const {
    filterOption,
    handleFilterChange,
    currentPage,
    handlePageChange,
    paginatedList,
    selectedIds,
    handleItemSelection,
    isDirty,
    setIsDirty,
    toggleViewMode,
    isTableView,
    initialSelectedItems,
    filteredList,
    pageSize,
    setPageSize,
  } = useTabState<RoadMapRole>({
    initialItems: allRoles,
    selectedItems: selectedRoles,
  });

  const selectedCount = selectedIds.size;

  useEffect(() => {
    setIsDirty(false);
  }, []);

  const handleItemClick = (id: string) => {
    handleItemSelection(id);
  };

  const handleSaveAndContinue = async () => {
    try {
      const payload: RoadMapPayLoad = {
        id: pathId,
        roles: filteredList,
      };
      await upsertRoadMap(payload);
      message.success("Roles successfully saved.");
      initialSelectedItems.current = new Set(selectedIds);
      onTabContentChange("3");
    } catch {
      message.error("Failed to save roles. Please try again.");
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      width: 60,
      render: (_: string, record: RoadMapRole) => (
        <Checkbox isChecked={selectedIds.has(record.id)} />
      ),
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: RoadMapRole) => (
        <div
          className="text-base font-normal leading-6 w-auto"
          title={record.name}
        >
          {record.name}
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
      width: 180,
      render: (_: string, record: RoadMapRole) => (
        <RowSection value={record.totalEmployees} />
      ),
    },
    {
      title: "Monthly Hours",
      dataIndex: "monthlyTimeSaved",
      key: "monthlyTimeSaved",
      width: 180,
      render: (_: string, record: RoadMapRole) => (
        <RowSection value={record.monthlyTimeSaved} />
      ),
    },
  ];

  return (
    <>
      <TabHeader
        title="Choose the roles to focus on."
        description="The roles below are filtered to focus on the the choices you made in the previous steps with product, team, and tasks. Select the roles that you would like to include in your roadmap."
        tabStep="3"
        isDirty={isDirty}
        selectedCount={selectedCount}
        onTabContentChange={handleSaveAndContinue}
        selectedText="role"
      />
      <div className="p-4">
        <Row gutter={16} className="mb-4 flex items-center">
          <Col span={8} className="flex items-center">
            <div className="text-lg font-bold leading-7 pr-3">Show</div>
            <FilterOptions
              filterOption={filterOption}
              onChange={handleFilterChange}
              orderByLabel=""
              radioOptions={[
                { value: "allItems", label: "All Roles" },
                { value: "selectedItems", label: "Selected Roles" },
              ]}
            />
          </Col>
          {/* FIXME: hide this feature will open next sprint  */}
          <Col span={16} className="hidden">
            <div className="flex justify-end items-center space-x-4">
              <PageSizeSelect
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <ViewSwitcher
                isTableView={isTableView}
                toggleViewMode={toggleViewMode}
              />
            </div>
          </Col>
        </Row>

        {loading ? (
          <Loader />
        ) : isTableView ? (
          <Table
            columns={columns}
            rowKey="id"
            dataSource={paginatedList}
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleItemClick(record.id),
              className: "cursor-pointer",
            })}
          />
        ) : (
          <Row>
            {isEmpty(paginatedList) ? (
              <Empty />
            ) : (
              paginatedList.map((role) => (
                <Col xs={24} md={8} lg={8} key={role.id}>
                  <CardView
                    icon1={
                      <ClockCountIcon
                        fill={theme.figmaColor.sky300}
                        width={32}
                        height={32}
                      />
                    }
                    title={role.name}
                    monthlyHours={role.monthlyTimeSaved}
                    totalEmployees={role.totalEmployees}
                    isActive={selectedIds.has(role.id)}
                    onCheck={() => handleItemClick(role.id)}
                    tabKey={"3"}
                    id={role.id}
                    onCardClick={() => {}}
                  />
                </Col>
              ))
            )}
          </Row>
        )}
        <Row>
          <div className="flex justify-start pt-4">
            <PaginationList
              currentPage={currentPage}
              pageSize={9}
              total={filteredList.length}
              onPageChange={handlePageChange}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

export default Tab3Content;
