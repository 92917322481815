import React from "react";
import { Image } from "antd";
import logoURL from "assets/images/logo.png";

const FooterLogo: React.FC = () => {
  return (
    <div className="flex items-center">
      <Image width={48} src={logoURL} alt="Logo" preview={false} />
      <div className="text-black font-black text-[19px] min-w-48 pl-5">
        Blue
      </div>
    </div>
  );
};

export default FooterLogo;
