import graphQLClient from "common/client/graphQLClient";

import { GET_ME } from "./userQueries";

export const fetchUserInfo = async () => {
  const response = await graphQLClient.query({
    query: GET_ME,
    fetchPolicy: "network-only",
  });
  const data = response.data.me;
  sessionStorage.setItem("userInfo", JSON.stringify(data));
  return data;
};
