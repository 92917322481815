import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, Form } from "antd";
import { ADMIN_ROLE } from "common/constants";

const { Option } = Select;

interface Props {
  visible: boolean;
  tenants: string[];
  onClose: () => void;
  onInvite: (email: string, role: string, tenants: string[]) => void;
  existingAdmins: string[];
}

const { SUPER_ADMIN, BLUE_ADMIN } = ADMIN_ROLE;

const InviteUserModal: React.FC<Props> = ({
  visible,
  tenants,
  onClose,
  onInvite,
  existingAdmins,
}) => {
  const [form] = Form.useForm();
  const [role, setRole] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleInvite = () => {
    form
      .validateFields()
      .then((values) => {
        onInvite(values.email, values.role, values.tenants || []);
        form.resetFields();
        onClose();
      })
      .catch((error) => {
        console.log("Validate Failed:", error);
      });
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setRole("");
      setIsButtonDisabled(true);
    }
  }, [visible, form]);

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    const values = form.getFieldsValue();
    const isFormFilled =
      values.email && values.role && (role !== BLUE_ADMIN || values.tenants);

    setIsButtonDisabled(hasErrors || !isFormFilled);
  };

  return (
    <Modal
      title="Invite User"
      open={visible}
      centered
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="invite"
          type="primary"
          onClick={handleInvite}
          disabled={isButtonDisabled}
        >
          Invite
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFieldsChange={onFieldsChange}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email is required." },
            { type: "email", message: "Invalid email format." },
            { max: 255, message: "Email has a max length of 255 characters." },
            {
              validator: (_, value) =>
                value && existingAdmins.includes(value)
                  ? Promise.reject(new Error("Email must be unique."))
                  : Promise.resolve(),
            },
          ]}
        >
          <Input placeholder="Enter admin email" maxLength={255} />
        </Form.Item>

        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: "Role is required." }]}
        >
          <Select
            onChange={(value) => {
              setRole(value);
              onFieldsChange();
            }}
            placeholder="Select role"
          >
            <Option value={SUPER_ADMIN}>Super Admin</Option>
            <Option value={BLUE_ADMIN}>Blue Admin</Option>
          </Select>
        </Form.Item>

        {role === BLUE_ADMIN && (
          <Form.Item
            name="tenants"
            label="Tenants"
            rules={[
              { required: true, message: "Please select at least one tenant." },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select tenants"
              options={tenants.map((tenant) => ({
                value: tenant,
                label: tenant,
              }))}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
