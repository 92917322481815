import React from "react";
import { Select } from "antd";
import { CaretDownIcon } from "components/Icon";

const { Option } = Select;

interface Props {
  pageSize: number;
  onPageSizeChange: (value: number) => void;
}

const PageSizeSelect: React.FC<Props> = ({ pageSize, onPageSizeChange }) => {
  return (
    <Select
      defaultValue={pageSize}
      onChange={onPageSizeChange}
      className="w-32 border-black border rounded-md h-10 font-medium text-base leading-6"
      suffixIcon={<CaretDownIcon />}
    >
      <Option value={6}>6 per page</Option>
      <Option value={10}>10 per page</Option>
      <Option value={20}>20 per page</Option>
    </Select>
  );
};

export default PageSizeSelect;
