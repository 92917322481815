import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserToken } from "utils/storage";

const PublicRoutes = () => {
  const isAuth = !!getUserToken();
  return isAuth ? <Navigate to="/ai-opportunity" /> : <Outlet />;
};

export default PublicRoutes;
