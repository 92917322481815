import React from "react";
import { Row, Col } from "antd";
import { ClockCountIcon, TeamIcon, UserCircleIcon } from "components/Icon";
import theme from "assets/styles/theme";
import { ImpactedTask } from "models/workforceModel";
import formatNumber from "utils/formatNumber";
import { isUndefined } from "lodash";

interface TaskProps extends ImpactedTask {
  isViewTeam?: boolean;
}

interface IconProps {
  icon: React.ReactNode;
  amount: string;
  currency: string;
}

const IconSection: React.FC<IconProps> = ({ icon, amount, currency }) => (
  <div className="flex items-center">
    <div className="pr-2">{icon}</div>
    <div>
      <span className="text-xl font-semibold leading-7 pr-1">{amount}</span>
      <span className="text-base font-normal leading-6">{currency}</span>
    </div>
  </div>
);

const TaskSection: React.FC<TaskProps> = ({
  title,
  description,
  totalEmployees,
  monthlyTimeSaved,
  totalTeams,
  isViewTeam = false,
}) => {
  const employees = formatNumber(totalEmployees).amount;
  const hours = formatNumber(monthlyTimeSaved).amount;

  const teams =
    isViewTeam && !isUndefined(totalTeams)
      ? formatNumber(totalTeams).amount
      : "";

  return (
    <Row
      className={`w-full border rounded-md p-4 ${isViewTeam ? "h-56" : "h-52"}`}
      gutter={[8, 0]}
    >
      <Col span={24}>
        <div className="text-base font-bold leading-5">{title}</div>
      </Col>
      <Col span={24}>
        <div className="text-sm font-normal leading-5">{description}</div>
      </Col>
      <Col span={12}>
        <IconSection
          icon={<ClockCountIcon fill={theme.figmaColor.sky500} />}
          amount={hours}
          currency={"hours/mo."}
        />
      </Col>
      <Col span={12}>
        <IconSection
          icon={<UserCircleIcon fill={theme.figmaColor.sky500} />}
          amount={employees}
          currency={"employee(s)"}
        />
      </Col>
      {isViewTeam && (
        <Col span={12} className="w-1/2">
          <IconSection
            icon={<TeamIcon fill={theme.figmaColor.sky500} />}
            amount={teams}
            currency={"team(s)"}
          />
        </Col>
      )}
    </Row>
  );
};

export default TaskSection;
