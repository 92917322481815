import graphQLClient from "common/client/graphQLClient";

import {
  GET_ROLE_BY_NAME,
  GET_ROLE_LIST,
  GET_TEAM_BY_NAME,
  GET_TEAM_LIST,
} from "./workforceQueries";

export const getWorkforceTeams = async () => {
  const response = await graphQLClient.query({
    query: GET_TEAM_LIST,
  });
  return response.data.getTeamNamesInOrg;
};

export const getWorkforceTeamByName = async (name: string) => {
  const response = await graphQLClient.query({
    query: GET_TEAM_BY_NAME,
    variables: {
      teamName: name,
    },
  });
  return response.data.exploreTeam;
};

export const getWorkforceRoles = async () => {
  const response = await graphQLClient.query({
    query: GET_ROLE_LIST,
  });
  return response.data.exploreRoles.roles;
};

export const getWorkforceRoleByName = async (name: string) => {
  const response = await graphQLClient.query({
    query: GET_ROLE_BY_NAME,
    variables: {
      roleTitle: name,
    },
  });
  return response.data.exploreRole;
};

export const updateTaskImpactSlider = async (taskImpactSlider: number) => {
  localStorage.setItem("taskImpactSlider", taskImpactSlider.toString() || "0");
};
