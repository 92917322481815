import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const CompassIcon: React.FC<IconProps> = ({
  fill = theme.color.icon.default,
  width = 32,
  height = 32,
}) => (
  <span className="ant-menu-item-icon">
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path
        d="M16 3.25C13.4783 3.25 11.0132 3.99777 8.91648 5.39876C6.81976 6.79975 5.18556 8.79103 4.22054 11.1208C3.25552 13.4505 3.00303 16.0141 3.49499 18.4874C3.98696 20.9607 5.20127 23.2325 6.98439 25.0156C8.76751 26.7987 11.0393 28.0131 13.5126 28.505C15.9859 28.997 18.5495 28.7445 20.8792 27.7795C23.209 26.8144 25.2003 25.1802 26.6012 23.0835C28.0022 20.9868 28.75 18.5217 28.75 16C28.746 12.6197 27.4015 9.379 25.0112 6.98877C22.621 4.59854 19.3803 3.25397 16 3.25ZM16 27.25C13.775 27.25 11.5999 26.5902 9.74984 25.354C7.89979 24.1179 6.45785 22.3609 5.60636 20.3052C4.75488 18.2495 4.53209 15.9875 4.96617 13.8052C5.40025 11.6229 6.47171 9.61839 8.04505 8.04505C9.6184 6.47171 11.623 5.40025 13.8052 4.96617C15.9875 4.53208 18.2495 4.75487 20.3052 5.60636C22.3609 6.45784 24.1179 7.89978 25.354 9.74984C26.5902 11.5999 27.25 13.775 27.25 16C27.2467 18.9827 26.0604 21.8422 23.9513 23.9513C21.8422 26.0604 18.9827 27.2467 16 27.25ZM21.665 9.32875L13.665 13.3287C13.5194 13.4014 13.4014 13.5194 13.3288 13.665L9.32876 21.665C9.27166 21.7794 9.24474 21.9064 9.25056 22.0341C9.25637 22.1618 9.29472 22.2859 9.36197 22.3946C9.42922 22.5033 9.52314 22.593 9.6348 22.6552C9.74647 22.7174 9.87218 22.75 10 22.75C10.1162 22.7498 10.2309 22.7229 10.335 22.6712L18.335 18.6712C18.4806 18.5986 18.5986 18.4806 18.6713 18.335L22.6713 10.335C22.7417 10.1942 22.766 10.0348 22.7408 9.87944C22.7156 9.72405 22.6421 9.58053 22.5308 9.46922C22.4195 9.35791 22.276 9.28444 22.1206 9.25923C21.9652 9.23402 21.8058 9.25834 21.665 9.32875ZM17.4413 17.4412L11.6775 20.3225L14.5525 14.5588L20.3163 11.6838L17.4413 17.4412Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default CompassIcon;
