import React from "react";
import { Row, Col } from "antd";
import formatNumber, { FormattedNumber } from "utils/formatNumber";

interface Props {
  title?: string;
  value: number;
  description: string;
  isViewCurrency?: boolean;
  isGradient?: boolean;
  textClassName?: string;
}

const MetricsSection: React.FC<Props> = ({
  title,
  value,
  description,
  isViewCurrency = false,
  isGradient = false,
  textClassName = "",
}) => {
  const { amount, currency }: FormattedNumber = formatNumber(value);
  const contentClassName = isGradient
    ? `font-black pb-2 text-5xl !text-gradient-base text-gradient-purple ${textClassName}`
    : `font-black pb-2 text-4xl ${textClassName}`;

  return (
    <Row gutter={[16, 0]} className="border-none">
      <Col span={24}>
        <div className={`font-bold text-base pb-2 ${textClassName}`}>
          {title}
        </div>
      </Col>
      <Col span={24}>
        <div className={contentClassName}>
          {isViewCurrency && "$"}
          {""}
          {amount}
          {currency}
        </div>
      </Col>
      <Col span={24}>
        <div className={`text-base font-normal leading-6 ${textClassName}`}>
          {description}
        </div>
      </Col>
    </Row>
  );
};

export default MetricsSection;
