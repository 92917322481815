import React from "react";
import { Form, Button, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import TextInput from "components/Input/TextInput";
import { SubmitButton } from "components/Button";

import { authRules } from "../validationRules";

interface Props {
  onFinish: (value: { email: string }) => void;
  onCancel: () => void;
  form: FormInstance;
}

const ForgotPasswordForm: React.FC<Props> = ({ onFinish, onCancel, form }) => {
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      requiredMark={false}
      name="forgot-password"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      className="w-full"
      layout="vertical"
    >
      <Form.Item
        name="email"
        label={<span className="font-bold">{t("authFlow.label.email*")}</span>}
        className="w-full"
        rules={authRules.email}
        normalize={(value) => value.trim()}
      >
        <TextInput placeholder="Enter your work email" />
      </Form.Item>

      <Form.Item className="pt-2">
        <SubmitButton form={form} loading={false}>
          {t("authFlow.button.resetPwd")}
        </SubmitButton>

        <Button
          type="default"
          className="min-w-32 float-right"
          onClick={onCancel}
        >
          {t("authFlow.button.cancel")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
