import React, { useEffect, useState } from "react";
import { Upload, Button, message, Select, UploadFile } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormContainer } from "components/Layout";
import { Tenant } from "models/adminModel";
import { getTenants, uploadCSV } from "services/adminService";

import UploadModal, { UploadErrors } from "./UploadModal";

const { Option } = Select;

const UploadPage: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenantId, setSelectedTenantId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<UploadErrors>({
    missingInvalidCells: 0,
    duplicatedRows: 0,
  });

  useEffect(() => {
    getTenants()
      .then(setTenants)
      .catch((error) => console.error("Error fetching tenants:", error));
  }, []);

  const uploadProps = {
    onRemove: () => setFileList([]),
    beforeUpload: (file: UploadFile) => {
      if (file.type !== "text/csv") {
        message.error("You can only upload CSV files!");
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleUpload = async () => {
    if (fileList.length === 0 || !selectedTenantId) {
      message.error(
        fileList.length > 0 ? "Please select a tenant." : "No file selected."
      );
      return;
    }

    localStorage.setItem("tenantId", selectedTenantId);
    setLoading(true);

    try {
      const { data } = await uploadCSV(fileList[0], false);
      if (data.uploadEmployeeCsv?.success) {
        message.success("Upload Success");
        setFileList([]);
      } else {
        setUploadErrors(data.uploadEmployeeCsv.error);
        setIsModalVisible(true);
      }
    } catch (error_) {
      console.error(error_);
      message.error("Upload failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setFileList([]);
  };

  const handleSkipRows = async () => {
    setIsModalVisible(false);
    message.success("Skipped invalid rows and continued upload.");
    setLoading(true);
    try {
      const { data } = await uploadCSV(fileList[0], true);
      if (data.uploadEmployeeCsv?.success) {
        message.success("Upload Success");
        setFileList([]);
      }
    } catch (error_) {
      console.error(error_);
      message.error("Upload failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer className="!h-[calc(100vh-80px)]">
      <h1 className="text-center text-2xl pb-4">Upload File</h1>
      <div className="flex flex-col">
        <Select
          placeholder="Select Tenant"
          onChange={setSelectedTenantId}
          className="mb-4 w-full max-w-lg"
        >
          {tenants.map((tenant) => (
            <Option key={tenant.id} value={tenant.id}>
              {tenant.name}
            </Option>
          ))}
        </Select>
        <Upload {...uploadProps} className="w-full max-w-lg">
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0 || loading}
          loading={loading}
          className="mt-4 text-left w-1/2"
        >
          {loading ? "Uploading" : "Start Upload"}
        </Button>
      </div>
      <UploadModal
        isVisible={isModalVisible}
        onCancel={handleModalCancel}
        onSkip={handleSkipRows}
        errors={uploadErrors}
      />
    </FormContainer>
  );
};

export default UploadPage;
