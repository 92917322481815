import React from "react";
import { Checkbox } from "antd";

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const PermissionCheckbox: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />
  );
};

export default PermissionCheckbox;
