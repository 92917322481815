import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BackToLoginButton } from "components/Button";

const PermissionDenied: React.FC = () => {
  return (
    <div className="flex min-h-screen bg-white items-center justify-center">
      <div className="w-24">
        <ExclamationCircleOutlined className="text-6xl text-purple-500 mb-4" />
      </div>
      <div className="flex flex-col p-6">
        <h1 className="text-6xl font-bold text-purple-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-purple-700 mb-6">
          Page Not Found
        </h2>
        <p className="text-lg text-gray-600 mb-4">
          The page you’re looking for doesn’t exist.
        </p>
        <p className="text-lg text-gray-600 mb-8">
          Please contact <span className="font-bold">KPMG support</span> to get
          more information.
        </p>
        <BackToLoginButton
          className="!w-1/2"
          backToHomePage={true}
          buttonText="Back to Home Page"
        />
      </div>
    </div>
  );
};

export default PermissionDenied;
