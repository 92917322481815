import React from "react";
import formatNumber from "utils/formatNumber";

interface Props {
  teamsCount: number;
  jobsCount: number;
  tasksCount: number;
}

const TagItem = ({ count, text }: { count: number | string; text: string }) => {
  return (
    <span className="p-2 rounded-lg bg-white15">
      <span className="font-bold">{count}</span>
      <span> {text}</span>
    </span>
  );
};

const Tags: React.FC<Props> = ({ teamsCount, jobsCount, tasksCount }) => {
  const { amount: formattedTasksCount } = formatNumber(tasksCount);
  return (
    <div className="text-xl font-extralight leading-7">
      The following is an evaluation of{" "}
      <TagItem count={teamsCount} text="team(s)" /> across{" "}
      <TagItem count={jobsCount} text="job(s)" /> and{" "}
      <TagItem count={formattedTasksCount} text="task(s)" />
    </div>
  );
};

export default Tags;
