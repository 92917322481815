import React from "react";
import { Input, InputProps } from "antd";

interface Props extends InputProps {
  className?: string;
}

const PwdInput: React.FC<Props> = (props) => {
  return (
    <Input.Password
      {...props}
      className={`${props.className || ""}`}
      style={{ height: 36 }}
    />
  );
};

export default PwdInput;
