import React from "react";
import { TeamRole } from "models/workforceModel";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";

import SearchRoleTable from "../RoleScreen/RoleTable";

import TextSection from "./TextSection";

interface Props {
  tableData: TeamRole[];
}

const AugmentedRoles: React.FC<Props> = ({ tableData }) => {
  const navigate = useNavigate();

  const handleRowChange = (key: string) => {
    navigate(`/ai-workforce-potential?role=${encodeURIComponent(key)}`, {
      replace: true,
    });
    window.location.reload();
  };

  if (isEmpty(tableData)) {
    return <Empty />;
  }

  return (
    <>
      <TextSection
        className="text-2xl font-light leading-8 w-full py-2"
        text={"Most impacted roles in this team."}
      />
      <TextSection
        className="text-lg font-normal leading-7 pb-6"
        text={
          "Consider the following roles in this team for the highest potential benefit through adopting AI in their work."
        }
      />
      <SearchRoleTable
        isHideTeam={true}
        roles={tableData}
        enableSearch={false}
        onRowChange={handleRowChange}
      />
    </>
  );
};

export default AugmentedRoles;
