export interface Tenant {
  id: string;
  name: string;
}

export interface RoleRow {
  id: string;
  name: string;
  description: string;
  totalPermissions: number;
}

export interface PermissionResponse {
  id: string;
  name: string;
  action: string;
}

export interface PermissionValues {
  read?: boolean;
  create?: boolean;
  update?: boolean;
  delete?: boolean;
}

export interface Permission {
  name: string;
  permissions: PermissionValues;
  ids: string[];
}

export interface UpsertRolePayload {
  id?: string;
  name: string;
  description: string;
  isActive: boolean;
  permissions: number[];
}

export interface RoleDetail {
  name: string;
  description: string;
  rolePermissions: Permission[];
}

export const transformPermissions = (
  data: PermissionResponse[]
): Permission[] => {
  const actionToPermissionKey = (action: string) => {
    if (action.includes("create")) return "create";
    if (action.includes("read")) return "read";
    if (action.includes("update")) return "update";
    if (action.includes("delete")) return "delete";
  };

  const permissionMap: Record<string, Permission> = {};

  for (const { id, name, action } of data) {
    if (!permissionMap[name]) {
      permissionMap[name] = {
        name,
        permissions: {
          read: false,
          create: false,
          update: false,
          delete: false,
        },
        ids: [],
      };
    }

    const permissionKey = actionToPermissionKey(action);
    if (permissionKey) {
      permissionMap[name].permissions[permissionKey] = false;
    }
    permissionMap[name].ids.push(id);
  }

  return Object.values(permissionMap);
};

export const convertPayloadToCombinedPermissions = (
  payload: Permission[]
): number[] => {
  const permissionIndexMap: { [key in keyof PermissionValues]: number } = {
    read: 0,
    create: 1,
    update: 2,
    delete: 3,
  };

  const permissionSet = new Set<number>();

  for (const item of payload) {
    const { permissions: items, ids } = item;

    for (const [permission, index] of Object.entries(permissionIndexMap)) {
      if (items[permission as keyof PermissionValues] && ids[index]) {
        permissionSet.add(Number(ids[index]));
      }
    }
  }

  return [...permissionSet];
};

export const combineRolePermissions = (
  allPermissions: Permission[],
  permissions: PermissionResponse[]
): Permission[] => {
  const actionMap: Record<string, keyof Permission["permissions"]> = {
    update: "update",
    delete: "delete",
    read: "read",
    create: "create",
  };

  return allPermissions.map((role) => {
    const updatedPermissions = { ...role.permissions };
    for (const { id, action } of permissions) {
      if (role.ids.includes(id)) {
        const actionKey = action.split(".").pop();
        if (actionKey && actionMap[actionKey]) {
          updatedPermissions[actionMap[actionKey]] = true;
        }
      }
    }
    return { ...role, permissions: updatedPermissions };
  });
};
