import trim from "lodash/trim";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "common/store";
import { validateInvitation, register } from "services/authService";
import { FormContainer } from "components/Layout";
import { Form } from "antd";
import { ErrorForm, SuccessForm } from "components/Form";
import Loader from "components/Loading/Loader";
import { useQuery } from "hooks";
import { ErrorSpace } from "components/Alert";

import AuthCode from "../AuthCode";
import AuthLogo from "../AuthLogo";

import GetStartedForm from "./GetStartedForm";

const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const isRegister = useSelector((state) => state.auth.isRegister);
  const isInvitationValid = useSelector(
    (state) => state.auth.isInvitationValid
  );

  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerExpired, setRegisterExpired] = useState(false);

  const [form] = Form.useForm();

  const email = useQuery().get("email") as string;
  const code = useQuery().get("code") as string;

  useEffect(() => {
    const effect = async () => {
      if (!code || !email) {
        navigate("/login");
      } else if (!registerExpired) {
        dispatch(validateInvitation({ accessCode: code, email }));
        setRegisterExpired(true);
      }
      if (isRegister && !error && !loading) {
        setRegisterSuccess(true);
      }
    };

    effect();
  }, [
    code,
    email,
    isRegister,
    error,
    loading,
    navigate,
    dispatch,
    registerExpired,
  ]);

  const onFinish = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    dispatch(
      register({
        accessCode: code,
        email,
        username: email,
        password: values.password,
        firstName: trim(values.firstName),
        lastName: trim(values.lastName),
      })
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (!isInvitationValid) {
    return (
      <FormContainer>
        <AuthLogo />
        <ErrorForm error={error} />
      </FormContainer>
    );
  }

  return (
    <FormContainer className="pt-10">
      <AuthLogo />
      {error && <ErrorSpace message={error} />}
      {registerSuccess ? (
        <SuccessForm text={"Account created successfully!"} />
      ) : (
        <>
          <AuthCode
            code={code}
            content={"You received this with your access link."}
            title={"Access Code"}
          />
          <GetStartedForm
            onFinish={onFinish}
            loading={loading}
            form={form}
            email={email}
          />
        </>
      )}
    </FormContainer>
  );
};

export default GetStarted;
