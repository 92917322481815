import graphQLClient from "common/client/graphQLClient";
import { SetUserPreferenceInput } from "models/opportunityModel";

import {
  GET_ADOPTION_SLIDER,
  GET_OPPORTUNITIES,
  SET_ADOPTION_SLIDER,
} from "./opportunityQueries";

export const getOpportunity = async () => {
  const response = await graphQLClient.query({ query: GET_OPPORTUNITIES });
  return response.data.getAIOpportunity;
};

export const getAdoptionSlider = async () => {
  const response = await graphQLClient.query({
    query: GET_ADOPTION_SLIDER,
  });

  return response.data.getUserPreference;
};

export const updateAdoptionSlider = async (adoptionSlider: number) => {
  const payload: SetUserPreferenceInput = {
    adoptionSlider,
  };

  const response = await graphQLClient.mutate({
    mutation: SET_ADOPTION_SLIDER,
    variables: {
      setUserPreferenceInput: payload,
    },
  });
  return response;
};
