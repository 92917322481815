import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoutSuccess as reset } from "slices/authSlice";
import { useDispatch } from "common/store";

interface Props {
  className?: string;
  isLink?: boolean;
  backToHomePage?: boolean;
  homePageURL?: string;
  buttonText?: string;
}

const BackToLoginButton: React.FC<Props> = ({
  className,
  isLink = false,
  backToHomePage = false,
  homePageURL = "/",
  buttonText,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBackToLogin = () => {
    if (backToHomePage) {
      navigate(homePageURL);
    } else {
      dispatch(reset());
      navigate("/login");
    }
  };

  const displayText = buttonText || t("authFlow.button.backTologin");

  if (isLink) {
    return (
      <div className="cursor-pointer" onClick={handleBackToLogin}>
        {displayText}
      </div>
    );
  }

  return (
    <Button
      type="primary"
      className={`w-full text-white hover:bg-purple-600 ${className || ""}`}
      onClick={handleBackToLogin}
    >
      {displayText}
    </Button>
  );
};

export default BackToLoginButton;
