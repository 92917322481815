import React from "react";
import { Form } from "antd";
import { BackToLoginButton } from "components/Button";

interface Props {
  text: string;
}

const SuccessForm: React.FC<Props> = ({ text }) => {
  return (
    <div className="max-w-[360px] mx-auto p-4">
      <p className="text-center text-black-500 mb-2 text-[14px] font-normal">
        {text}
      </p>
      <Form.Item className="w-full py-2">
        <BackToLoginButton />
      </Form.Item>
    </div>
  );
};

export default SuccessForm;
