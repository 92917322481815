import React from "react";
import type { FormInstance } from "antd";
import { Button, Form } from "antd";

interface SubmitButtonProps {
  form: FormInstance;
  loading: boolean;
  className?: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  form,
  children,
  loading,
  className,
}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      loading={loading}
      className={className}
      disabled={!submittable}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
