import React from "react";
import { Radio, ConfigProvider } from "antd";
import theme from "assets/styles/theme";

interface RadioOption {
  value: string;
  label: string;
}

interface Props {
  filterOption: string;
  onChange: (value: string) => void;
  orderByLabel: string;
  radioOptions: RadioOption[];
}

const FilterOptions: React.FC<Props> = ({
  filterOption,
  onChange,
  orderByLabel,
  radioOptions,
}) => {
  const color = theme.color.radio;
  const customtheme = {
    token: {
      colorBorder: color.primary,
      colorPrimary: color.background,
      colorPrimaryBorder: color.primary,
    },
  };

  return (
    <ConfigProvider theme={customtheme}>
      <>
        <div className="pb-4 text-lg font-bold leading-7">{orderByLabel}</div>
        <Radio.Group
          onChange={(e) => onChange(e.target.value)}
          value={filterOption}
        >
          {radioOptions.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </>
    </ConfigProvider>
  );
};

export default FilterOptions;
