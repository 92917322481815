import React from "react";
import { Layout } from "antd";
import { ConnectWithUs, FooterColumn as Column } from "models/footerModel";

import FooterLogo from "./Items/FooterLogo";
import FooterColumn from "./Items/FooterColumn";
import FooterLink from "./Items/FooterLink";

const { Footer: AntdFooter } = Layout;

interface Props {
  columns: Column[];
  connectWithUs: ConnectWithUs;
}

const FooterWithContent: React.FC<Props> = ({ columns, connectWithUs }) => {
  return (
    <AntdFooter className="flex flex-col items-start p-5 bg-white pl-10">
      <div className="mb-5">
        <FooterLogo />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 w-full mb-5">
        {columns.map((item, index) => (
          <div key={index}>
            <FooterColumn {...item} />
          </div>
        ))}
      </div>
      <FooterLink {...connectWithUs} />
    </AntdFooter>
  );
};

export default FooterWithContent;
