import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const PencilSimpleIcon: React.FC<IconProps> = ({
  fill = theme.color.icon.default,
  width = 32,
  height = 32,
}) => (
  <span className="ant-menu-item-icon">
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path
        d="M28.2375 9.34741L22.6513 3.76116C22.4888 3.59864 22.2958 3.46972 22.0835 3.38176C21.8712 3.29381 21.6436 3.24854 21.4138 3.24854C21.1839 3.24854 20.9563 3.29381 20.744 3.38176C20.5317 3.46972 20.3388 3.59864 20.1763 3.76116L4.7625 19.1762C4.59967 19.3384 4.47056 19.5313 4.3826 19.7437C4.29464 19.9561 4.24958 20.1838 4.25 20.4137V25.9999C4.25 26.464 4.43438 26.9092 4.76257 27.2373C5.09075 27.5655 5.53587 27.7499 6 27.7499H11.5863C11.8161 27.7503 12.0438 27.7053 12.2562 27.6173C12.4686 27.5294 12.6615 27.4002 12.8238 27.2374L28.2375 11.8224C28.4 11.6599 28.5289 11.467 28.6169 11.2546C28.7049 11.0423 28.7501 10.8147 28.7501 10.5849C28.7501 10.3551 28.7049 10.1275 28.6169 9.91517C28.5289 9.70284 28.4 9.50992 28.2375 9.34741ZM11.7625 26.1762C11.7158 26.2231 11.6525 26.2496 11.5863 26.2499H6C5.9337 26.2499 5.87011 26.2236 5.82323 26.1767C5.77634 26.1298 5.75 26.0662 5.75 25.9999V20.4137C5.75028 20.3475 5.7768 20.2841 5.82375 20.2374L17 9.05991L22.9388 14.9999L11.7625 26.1762ZM27.1763 10.7624L24 13.9387L18.0613 7.99991L21.2375 4.82241C21.2607 4.79917 21.2883 4.78073 21.3186 4.76815C21.349 4.75557 21.3815 4.74909 21.4144 4.74909C21.4472 4.74909 21.4798 4.75557 21.5101 4.76815C21.5405 4.78073 21.568 4.79917 21.5913 4.82241L27.1763 10.4087C27.1995 10.4319 27.2179 10.4595 27.2305 10.4898C27.2431 10.5202 27.2496 10.5527 27.2496 10.5855C27.2496 10.6184 27.2431 10.6509 27.2305 10.6813C27.2179 10.7116 27.1995 10.7392 27.1763 10.7624Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default PencilSimpleIcon;
