import React, { useState } from "react";
import type { PaginationProps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Pagination } from "antd";
import theme from "assets/styles/theme";
import "./style.css";

interface Props {
  currentPage: number;
  pageSize: number;
  total: number;
  onPageChange: (page: number) => void;
  showSizeChanger?: boolean;
  showQuickJumper?: boolean;
  hideOnSinglePage?: boolean;
}

const PaginationList: React.FC<Props> = ({
  currentPage,
  pageSize,
  total,
  onPageChange,
  showSizeChanger = false,
  showQuickJumper = false,
  hideOnSinglePage = true,
}) => {
  const customtheme = {
    token: {
      colorBgContainer: theme.figmaColor.gray100,
      colorPrimary: theme.figmaColor.gray900,
      colorPrimaryBorder: theme.figmaColor.gray100,
    },
  };

  const [current, setCurrent] = useState(1);

  const lastPage = Math.ceil(total / pageSize);

  const handleOnPageChange = (page: number) => {
    setCurrent(page);
    onPageChange(page);
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "next") {
      const disabled = current === lastPage;
      return (
        <Button
          className={`bg-gray-100 border-none rounded-s-none rounded-e-2xl font-bold ${
            disabled && "!cursor-not-allowed !text-gray-300"
          }`}
          disabled
          iconPosition="end"
          icon={<RightOutlined />}
        >
          Next
        </Button>
      );
    }
    return originalElement;
  };

  return (
    <ConfigProvider theme={customtheme}>
      <Pagination
        itemRender={itemRender}
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={handleOnPageChange}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        hideOnSinglePage={hideOnSinglePage}
        responsive={true}
      />
    </ConfigProvider>
  );
};

export default PaginationList;
