import React from "react";

const InfoScreen: React.FC = () => {
  return (
    <div className="p-8 pb-0">
      <div className="text-4xl font-light leading-10 text-left text-white pb-5">
        Manage your tenants
      </div>
      <div className="text-xl font-light leading-7 text-left text-white">
        {" "}
        TBD
      </div>
    </div>
  );
};

export default InfoScreen;
