import React, { useEffect, useState } from "react";
import { FooterColumn, FooterPayload } from "models/footerModel";
import { getFooter } from "services/commonService";
import { Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import { getUserToken } from "utils/storage";

import FooterWithoutContent from "./FooterWithoutContent";
import FooterWithContent from "./FooterWithContent";

const Footer: React.FC = () => {
  const [footers, setFooters] = useState<FooterPayload>();
  const token = getUserToken();

  useEffect(() => {
    let isMounted = true; // track whether the component is mounted

    const fetchFooters = async () => {
      try {
        const data = await getFooter();
        if (isMounted) {
          setFooters(data);
        }
      } catch {
        if (isMounted) {
          setFooters(undefined);
        }
      }
    };

    fetchFooters();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!footers) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  const { columns = [], connectWithUs, getInTouch } = footers;
  let newColumns: FooterColumn[] = columns;

  if (!isEmpty(getInTouch)) {
    newColumns = [
      ...newColumns,
      {
        id: getInTouch?.id,
        title: getInTouch?.title,
        links: [
          {
            text: getInTouch?.message,
          },
        ],
      },
    ];
  }

  return !token || isEmpty(newColumns) ? (
    <FooterWithoutContent connectWithUs={connectWithUs} />
  ) : (
    <FooterWithContent columns={newColumns} connectWithUs={connectWithUs} />
  );
};

export default Footer;
