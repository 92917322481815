import React from "react";
import { ProductivityGainByLevel } from "models/workforceModel";
import { Col, Empty, Row } from "antd";
import { GainSection } from "components/Section";
import { CHART_COLORS_STYLES } from "common/constants";
import isEmpty from "lodash/isEmpty";

import TextSection from "./TextSection";

interface Props {
  data: ProductivityGainByLevel[];
}

const ProductivityGain: React.FC<Props> = ({ data = [] }) => {
  if (isEmpty(data)) {
    return <Empty />;
  }
  return (
    <>
      <TextSection
        className="text-2xl font-light leading-8 w-full py-2"
        text={"Distribution of productivity gain by level."}
      />
      <TextSection
        className="text-lg font-normal leading-7 pb-6"
        text={"Here is a breakdown across the levels in this team."}
      />
      <Row gutter={[32, 0]} className="w-full">
        {data.map((item, index) => (
          <Col span={8} key={index}>
            <GainSection
              label={`Top ${item.activities.length} activities:`}
              color={CHART_COLORS_STYLES[index % CHART_COLORS_STYLES.length]}
              {...item}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProductivityGain;
