import React, { FC, useState } from "react";
import { Card, Col, Row } from "antd";
import { SwitchGroupButton } from "components/Button";
import {
  TeamIcon as OriginalTeamIcon,
  UserCircleIcon as OriginalUserCircleIcon,
} from "components/Icon";
import { TabConfig } from "components/Button/SwitchGroupButton";

interface Props {
  onModeChange: (value: string) => void;
}

interface IconProps {
  fill: string;
}

const TeamIcon: FC<IconProps> = ({ fill }) => <OriginalTeamIcon fill={fill} />;
const UserCircleIcon: FC<IconProps> = ({ fill }) => (
  <OriginalUserCircleIcon fill={fill} />
);

const TopScreen: React.FC<Props> = ({ onModeChange }) => {
  const [mode, setMode] = useState("teams");

  const handleModeChange = (value: string) => {
    setMode(value);
    onModeChange(value);
  };

  const tabConfig: TabConfig[] = [
    {
      value: "teams",
      label: "High Impact Teams",
      icon: TeamIcon,
    },
    {
      value: "roles",
      label: "High Impact Roles",
      icon: UserCircleIcon,
    },
  ];

  return (
    <Card
      className="h-full w-full p-0 !shadow-none rounded-bl-none rounded-br-none"
      bordered={false}
    >
      <Row className="flex items-center">
        <Col span={18}>
          <SwitchGroupButton
            onModeChange={handleModeChange}
            tabConfig={tabConfig}
          />
        </Col>
      </Row>
      <Row>
        <p className="text-lg font-normal pt-6 leading-7">
          {mode === "teams" ? (
            <span>
              Consider these teams for early adoption – they have the highest
              potential productivity and capacity gain from working with
              generative AI.
            </span>
          ) : (
            <>
              <span>
                Consider the following roles across all teams – these roles have
                the highest potential benefit through adopting AI in their work.
              </span>
            </>
          )}
        </p>
      </Row>
    </Card>
  );
};

export default TopScreen;
