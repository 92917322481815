import React, { useEffect } from "react";
import { Col, Empty, Row, message } from "antd";
import { RoadMapPayLoad, RoadMapTask } from "models/roadMapModel";
import {
  fetchAllTasks,
  fetchSelectedTasks,
  upsertRoadMap,
} from "services/roadMapService";
import { useEntityData, useTabState } from "hooks";
import FilterOptions from "components/FilterOptions";
import { RowSection } from "components/Section";
import Checkbox from "components/Checkbox";
import { PageSizeSelect, PaginationList } from "components/Pagination";
import isEmpty from "lodash/isEmpty";
import Table from "components/Table";
import Loader from "components/Loading/Loader";

import CardView from "../CardView";

import TabHeader from "./TabHeader";
import ViewSwitcher from "./ViewSwitcher";

interface Props {
  onTabContentChange: (key: string) => void;
  isTabCompleted: boolean;
  onCardClick: (key: string, id: string) => void;
  selectedCardToToggle: string;
  pathId: string;
}

const Tab2Content: React.FC<Props> = ({
  onTabContentChange,
  isTabCompleted,
  onCardClick,
  selectedCardToToggle,
  pathId,
}) => {
  const {
    allEntities: allTasks,
    selectedEntities: selectedTasks,
    loading,
  } = useEntityData<RoadMapTask>(
    () => fetchAllTasks(pathId as string),
    () => fetchSelectedTasks(pathId as string)
  );

  const {
    filterOption,
    handleFilterChange,
    currentPage,
    handlePageChange,
    paginatedList,
    selectedIds,
    handleItemSelection,
    isDirty,
    setIsDirty,
    toggleViewMode,
    isTableView,
    initialSelectedItems,
    filteredList,
    pageSize,
    setPageSize,
  } = useTabState<RoadMapTask>({
    initialItems: allTasks,
    selectedItems: selectedTasks,
  });

  const handleItemClick = (id: string) => {
    if (isTabCompleted) {
      onCardClick("2", id);
    } else {
      handleItemSelection(id);
    }
  };

  useEffect(() => {
    setIsDirty(false);
    if (selectedCardToToggle) {
      handleItemSelection(selectedCardToToggle);
    }
  }, [selectedCardToToggle]);

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      width: 60,
      render: (_: string, record: RoadMapTask) => (
        <Checkbox isChecked={selectedIds.has(record.id)} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: string, record: RoadMapTask) => (
        <div
          className="text-base font-normal leading-6 w-auto"
          title={record.description}
        >
          {record.description}
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
      width: 180,
      render: (_: string, record: RoadMapTask) => (
        <RowSection value={record.totalEmployees} />
      ),
    },
    {
      title: "Roles",
      dataIndex: "totalRoles",
      key: "totalRoles",
      width: 180,
      render: (_: string, record: RoadMapTask) => (
        <RowSection value={record.totalRoles} />
      ),
    },
    {
      title: "Hours",
      dataIndex: "annualTimeSaved",
      key: "annualTimeSaved",
      width: 180,
      render: (_: string, record: RoadMapTask) => (
        <RowSection value={record.monthlyTimeSaved} />
      ),
    },
  ];

  const handleSaveAndContinue = async () => {
    const newTasks = allTasks.filter((task: RoadMapTask) =>
      selectedIds.has(task.id)
    );
    try {
      const payload: RoadMapPayLoad = {
        id: pathId,
        tasks: newTasks,
        roles: [],
      };
      await upsertRoadMap(payload);
      message.success("Tasks successfully saved.");
      initialSelectedItems.current = new Set(selectedIds);
      onTabContentChange("2");
    } catch {
      message.error("Failed to save tasks. Please try again.");
    }
  };

  return (
    <>
      <TabHeader
        title="Choose the work to focus on."
        description="The tasks below are the ones identified in the teams you chose for potential augmentation. Select the tasks to include in the roadmap and in the next step you can choose the roles that gain the highest benefit."
        tabStep="2"
        isDirty={isDirty}
        selectedCount={selectedIds.size}
        onTabContentChange={handleSaveAndContinue}
        selectedText="task"
      />
      <div className="p-4">
        <Row gutter={16} className="mb-4 flex items-center">
          <Col span={8} className="flex items-center">
            <div className="text-lg font-bold leading-7 pr-3">Show</div>
            <FilterOptions
              filterOption={filterOption}
              onChange={handleFilterChange}
              orderByLabel=""
              radioOptions={[
                { value: "allItems", label: "All Tasks" },
                { value: "selectedItems", label: "Selected Tasks" },
              ]}
            />
          </Col>
          {/* FIXME: hide this feature will open next sprint  */}
          <Col span={16} className="hidden">
            <div className="flex justify-end items-center space-x-4">
              <PageSizeSelect
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <ViewSwitcher
                isTableView={!isTableView}
                toggleViewMode={toggleViewMode}
              />
            </div>
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : isTableView ? (
          <Row>
            {isEmpty(paginatedList) ? (
              <Empty />
            ) : (
              paginatedList.map((team) => (
                <Col xs={24} md={8} lg={8} key={team.id}>
                  <CardView
                    title={team.description}
                    monthlyHours={team.monthlyTimeSaved}
                    totalEmployees={team.totalEmployees}
                    isActive={selectedIds.has(team.id)}
                    onCheck={() => handleItemClick(team.id)}
                    isTabCompleted={isTabCompleted}
                    onCardClick={onCardClick}
                    tabKey={"2"}
                    id={team.id}
                  />
                </Col>
              ))
            )}
          </Row>
        ) : (
          <Table
            columns={columns}
            rowKey="id"
            dataSource={paginatedList}
            pagination={false}
            scroll={{ y: 500, x: 800 }}
            onRow={(record) => ({
              onClick: () => handleItemClick(record.id),
              className: "cursor-pointer",
            })}
          />
        )}
        <Row>
          <div className="flex justify-start pt-4">
            <PaginationList
              currentPage={currentPage}
              pageSize={9}
              total={filteredList.length}
              onPageChange={handlePageChange}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

export default Tab2Content;
