import { ThemeConfig } from "antd";

import theme from "./theme";

const { figmaColor, color, font } = theme;

export const customAntdTheme: ThemeConfig = {
  token: {
    fontFamily: font.base.toString(),
  },
  components: {
    Input: {
      activeBorderColor: figmaColor.purple900,
      hoverBorderColor: figmaColor.purple900,
    },
    Slider: {
      trackBg: figmaColor.purple700,
      trackHoverBg: figmaColor.purple700,
      handleColor: figmaColor.purple900,
      handleActiveColor: figmaColor.purple900,
      dotBorderColor: figmaColor.gray600,
      dotActiveBorderColor: figmaColor.gray600,
      handleLineWidth: 10,
      handleLineWidthHover: 10,
      railSize: 12,
    },
    Radio: {
      buttonBg: figmaColor.gray75,
      buttonSolidCheckedActiveBg: figmaColor.gray900,
      buttonSolidCheckedBg: figmaColor.gray900,
      buttonSolidCheckedHoverBg: figmaColor.gray900,
      buttonColor: figmaColor.gray900,
    },
    Button: {
      colorPrimaryHover: color.button.primaryHover,
      colorPrimary: color.button.primary,
    },
    Menu: {
      itemHoverBg: color.menu.items.hoverBg,
      itemSelectedColor: color.menu.items.selectedColor,
      itemHoverColor: color.menu.items.hoverColor,
      itemColor: color.menu.items.color,
    },
    Carousel: {
      dotActiveWidth: 20,
      dotGap: 10,
    },
    Table: {
      headerBg: color.table.header.bg,
      borderColor: color.table.border,
      headerSplitColor: color.table.header.split,
    },
  },
};
