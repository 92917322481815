import { RoadMapPayLoad } from "models/roadMapModel";
import graphQLClient from "common/client/graphQLClient";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import {
  GET_ALL_ROLES,
  GET_ALL_TASKS,
  GET_ALL_TEAMS,
  GET_ROADMAP_BY_ID,
  GET_ROADMAPS,
  GET_SELECTED_ROLES,
  GET_SELECTED_TASKS,
  GET_SELECTED_TEAMS,
  GET_SUMMARY,
  UPSERT_ROADMAP,
} from "./roadMapQueries";

export const fetchRoadMaps = async () => {
  const response = await graphQLClient.query({ query: GET_ROADMAPS });
  const data = response.data.listRoadmap;
  return orderBy(
    data,
    (o) => {
      return Number(o.id);
    },
    ["desc"]
  );
};

export const fetchAllTeams = async () => {
  const response = await graphQLClient.query({ query: GET_ALL_TEAMS });
  return response.data.getTeams;
};

export const fetchSelectedTeams = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_SELECTED_TEAMS,
    variables: {
      id: Number.parseInt(id),
    },
  });
  return response.data.getRoadmap.teams;
};

export const fetchAllTasks = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_ALL_TASKS,
    variables: {
      roadmapId: Number.parseInt(id),
    },
  });
  return response.data.getTasks;
};

export const fetchSelectedTasks = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_SELECTED_TASKS,
    variables: {
      id: Number.parseInt(id),
    },
  });
  return response.data.getRoadmap.tasks;
};

export const fetchAllRoles = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_ALL_ROLES,
    variables: {
      roadmapId: Number.parseInt(id),
    },
  });
  return response.data.getRoles;
};

export const fetchSelectedRoles = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_SELECTED_ROLES,
    variables: {
      id: Number.parseInt(id),
    },
  });
  return response.data.getRoadmap.roles;
};

export const roadMapDetail = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_ROADMAP_BY_ID,
    variables: {
      id: Number.parseInt(id),
    },
  });
  const data = response.data.getRoadmap;
  if (!isEmpty(data)) {
    const sections: string[] = data.completedSteps;

    // FIXME:
    if (sections.includes("roles")) {
      sections.push("summary");
    }

    type Section = (typeof sections)[number];
    const sectionMapping: Record<Section, string> = {
      teams: "1",
      roles: "3",
      tasks: "2",
      summary: "4",
    };
    data.completedSteps = sections.map(
      (section: string | number) => sectionMapping[section]
    );
    return data;
  }
};
export const upsertRoadMap = async (payload: RoadMapPayLoad) => {
  const response = await graphQLClient.mutate({
    mutation: UPSERT_ROADMAP,
    variables: {
      input: payload,
    },
  });
  return response.data.upsertRoadmap;
};

export const fetchSummary = async (id: string) => {
  const response = await graphQLClient.query({
    query: GET_SUMMARY,
    variables: {
      roadmapId: Number.parseInt(id),
    },
  });
  const data = response.data.getSummary;

  if (!isEmpty(data)) {
    const chartPortionValues = [
      data.monthlyTimeSaved,
      data.otherRoadmapsMonthlyTimeSaved,
      data.organizationMonthlyTimeSaved,
    ];
    return {
      ...data,
      chartPortionValues,
    };
  }

  return [];
};
