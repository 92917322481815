import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "common/client/graphQLClient";
import { useAutoLogout } from "hooks";

import Router from "./components/Router";
const App: React.FC = () => {
  useAutoLogout();
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
