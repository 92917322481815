import { useState, useEffect, useCallback } from "react";
import parseError from "utils/error";

const useFetchData = <T, U = T>(
  fetchFunction: () => Promise<T>,
  transformFunction?: (data: T) => U
) => {
  const [data, setData] = useState<U>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchData = useCallback(async () => {
    let isMounted = true;
    setLoading(true);
    setError("");

    try {
      const result = await fetchFunction();
      if (isMounted) {
        const transformedData = transformFunction
          ? transformFunction(result)
          : (result as unknown as U);
        setData(transformedData);
      }
    } catch (error_) {
      if (isMounted) {
        setError(parseError(error_));
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    error,
    clearError: () => setError(""),
    refetch: fetchData,
  };
};

export default useFetchData;
