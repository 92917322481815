import React from "react";
import { Empty, Row } from "antd";
import sum from "lodash/sum";
import formatNumber from "utils/formatNumber";
import { useFetchData } from "hooks";
import { fetchSummary } from "services/roadMapService";
import isEmpty from "lodash/isEmpty";
import Loader from "components/Loading/Loader";

import TabHeader from "./TabHeader";

interface BarProps {
  data: number[];
}

interface Props {
  onTabContentChange: (key: string) => void;
  pathId: string;
}

const VerticalStackedBarChart: React.FC<BarProps> = ({ data }) => {
  const total = sum(data);

  const renderLegendItem = (color: string, label: string) => (
    <div key={label} className="flex items-center mr-8">
      <div className={`flex-shrink-0 rounded-full w-4 h-4 ${color} mr-1`} />
      <span className="text-base font-bold">{label}</span>
    </div>
  );

  const renderBar = (
    value: number,
    color: string,
    additionalClasses = "",
    isViewLabel = false
  ) => {
    const { amount } = formatNumber(value);
    const widthPercentage = `${(value / total) * 100}%`;

    return (
      <div
        className={`text-white block pt-3 h-full ${color} ${additionalClasses}`}
        style={{ width: widthPercentage }}
      >
        {isViewLabel && (
          <>
            <p className="text-gray50 text-2xl font-black leading-5 pl-4 pb-1">
              {amount}
            </p>
            <p className="pl-4">hours per month</p>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex m-4 ml-0 w-full">
        {renderLegendItem("text-gradient-purple", "This roadmap")}
        {renderLegendItem("text-gradient-sky", "Your Other Roadmaps")}
        {renderLegendItem("bg-gray100", "Total GenAI Potential")}
      </div>
      <div className="flex items-center w-full h-16">
        {renderBar(data[0], "text-gradient-purple", "rounded-l-lg", true)}
        {renderBar(data[1], "text-gradient-sky bg-green-500")}
        {renderBar(data[2], "bg-gray100", "rounded-r-lg")}
      </div>
    </>
  );
};

const Tab4Content: React.FC<Props> = ({ onTabContentChange, pathId }) => {
  const { data, loading } = useFetchData(() => fetchSummary(pathId));

  const renderRoadMapSummary = () => {
    if (isEmpty(data)) return;

    const { amount: hours, currency } = formatNumber(data?.monthlyTimeSaved);
    const { amount: totalHours, currency: totalCurrency } = formatNumber(
      data?.organizationMonthlyTimeSaved
    );

    return (
      <>
        <Row gutter={16} className="mb-4 flex items-center">
          <div className="text-3xl font-extrabold leading-9">
            This roadmap is {data?.percent.toFixed(1)}%, or {hours}
            {currency} hours, of the total {totalHours}
            {totalCurrency} GenAI potential for your workforce per month
          </div>
        </Row>
        <Row>
          <VerticalStackedBarChart data={data.chartPortionValues} />
        </Row>
      </>
    );
  };

  return (
    <>
      <TabHeader
        title="Summary of your roadmap"
        description="Here’s what you selected for this roadmap."
        tabStep="4"
        isViewSaveButton={false}
        onTabContentChange={onTabContentChange}
        isViewSelectedCount={false}
      />
      <div className="p-4 min-h-80">
        {loading ? <Loader /> : renderRoadMapSummary() || <Empty />}
      </div>
    </>
  );
};

export default Tab4Content;
