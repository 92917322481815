import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const UploadIcon: React.FC<IconProps> = ({
  stroke = theme.color.icon.default,
  width = 31,
  height = 30,
}) => (
  <span className="ant-menu-item-icon">
    <svg width={width} height={height} viewBox="0 0 31 30" fill="none">
      <path
        d="M29 19.5V25.5C29 26.2957 28.6839 27.0587 28.1213 27.6213C27.5587 28.1839 26.7957 28.5 26 28.5H5C4.20435 28.5 3.44129 28.1839 2.87868 27.6213C2.31607 27.0587 2 26.2957 2 25.5V19.5M23 9L15.5 1.5M15.5 1.5L8 9M15.5 1.5V19.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default UploadIcon;
