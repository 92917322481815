import React from "react";
import { Layout } from "antd";

import Footer from "../Footer";

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
  className?: string;
}

const FormContainer: React.FC<Props> = ({ children, className = "" }) => {
  const defaultClassName = "flex justify-center items-center h-screen";
  const combinedClassName = `${defaultClassName} ${className}`.trim();
  return (
    <>
      <Content className={combinedClassName}>
        <div className="min-w-96 max-w-80 min-h-64 p-8 flex-col justify-center items-center gap-8 bg-white rounded-lg shadow-lg">
          {children}
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default FormContainer;
