import React from "react";
import { Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { PwdInput, TextInput } from "components/Input";
import { SubmitButton } from "components/Button";

import { authRules } from "../validationRules";

interface Props {
  onFinish: (values: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => void;
  loading: boolean;
  form: FormInstance;
  email: string;
}

const ResetPasswordForm: React.FC<Props> = ({
  onFinish,
  loading,
  form,
  email,
}) => {
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      requiredMark={false}
      name="ResetPassword"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      className="w-full"
      layout="vertical"
    >
      <Form.Item
        name="email"
        label={<span className="font-bold">{t("authFlow.label.email")}</span>}
        className="w-full"
      >
        <TextInput placeholder={email} disabled={true} />
      </Form.Item>
      <Form.Item
        name="password"
        label={
          <span className="font-bold">{t("authFlow.label.newPassword*")}</span>
        }
        rules={authRules.password}
        className="w-full"
      >
        <PwdInput placeholder="Enter new password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label={
          <span className="font-bold">
            {t("authFlow.label.confirmPassword*")}
          </span>
        }
        rules={authRules.confirmPassword}
        className="w-full"
      >
        <PwdInput placeholder="Confirm your password" />
      </Form.Item>
      <Form.Item className="w-full py-2">
        <SubmitButton loading={loading} className="w-full" form={form}>
          Change Password
        </SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
