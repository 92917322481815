import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";
import { useFetchData } from "hooks";
import { roadMapDetail } from "services/roadMapService";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loading/Loader";

import TabGroup from "./TabGroup";

const RoadmapDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useFetchData(() => roadMapDetail(id as string));

  useEffect(() => {
    if (!loading && !data) {
      navigate("/adoption-roadmap?list");
    }
  }, [loading, data, navigate]);

  if (loading) return <Loader />;

  if (!data) return <></>;

  return (
    <MainLayout>
      <CardLayout>
        <BoxLayout className="bg-white shadow-gradient">
          <Col span={24}>
            <Row className="bg-transparent rounded-3xl">
              <TabGroup
                completedSteps={data?.completedSteps || []}
                name={data?.name || ""}
                pathId={id as string}
                type={data.type}
              />
            </Row>
          </Col>
        </BoxLayout>
      </CardLayout>
    </MainLayout>
  );
};

export default RoadmapDetail;
