import { Row } from "antd";
import React from "react";

const CardLayout: React.FC = ({ children }) => {
  return (
    <Row
      className="h-full p-4"
      style={{
        background:
          "linear-gradient(109.1deg, #510dbc 27.96%, #2f4cca 67.06%) top/100% 70vh no-repeat",
      }}
    >
      {children}
    </Row>
  );
};

export default CardLayout;
