export interface FormattedNumber {
  amount: string;
  currency: string;
}

export const formatAmount = (value: number): string => {
  const roundedAmount =
    value >= 100 ? Math.round(value) : Number.parseFloat(value.toFixed(1));
  return roundedAmount.toString().replace(/\.0$/, "");
};

const formatNumber = (value: number): FormattedNumber => {
  const K_THRESHOLD = 99_999;
  const LOCALE_FORMAT = "en-US";

  let formattedValue: number;
  let currency: string;

  if (value >= 1_000_000_000) {
    formattedValue = value / 1_000_000_000;
    currency = "B";
  } else if (value >= 1_000_000) {
    formattedValue = value / 1_000_000;
    currency = "M";
  } else if (value >= K_THRESHOLD) {
    formattedValue = value / 1000;
    currency = "K";
  } else {
    const amount = value.toFixed(0);
    const localeAmount =
      Number.parseFloat(amount).toLocaleString(LOCALE_FORMAT);
    return { amount: localeAmount, currency: "" };
  }

  const amount = formatAmount(formattedValue);
  return { amount, currency };
};

export default formatNumber;
