import { Tenant } from "models/adminModel";
import React from "react";

import DetailActions from "../DetailActions";

export const tenantColumns = (
  handleButtonClick: (key: string) => void,
  handleCreate: () => void,
  handleUpdate: () => void,
  handleDelete: () => void
) => [
  {
    title: "Tenant Name",
    dataIndex: "name",
    key: "name",
    render: (_: string, record: Tenant) => (
      <p
        className="text-base font-normal leading-6 truncate w-auto underline cursor-pointer"
        title={record.name}
        onClick={() => handleButtonClick(record.id.toString())}
      >
        {record.name}
      </p>
    ),
  },
  // {
  //   title: "Created At",
  //   dataIndex: "createdAt",
  //   key: "createdAt",
  //   render: (_: string, record: Tenant) => (
  //     <div
  //       className="text-base font-normal leading-6 truncate w-auto"
  //       title={record.createdAt}
  //     >
  //       {record.createdAt}
  //     </div>
  //   ),
  // },
  // {
  //   title: "Users",
  //   dataIndex: "users",
  //   key: "users",
  //   render: (_: string, record: Tenant) => (
  //     <RowSection
  //       value={record.totalUsers}
  //       description={`${record.totalActiveUsers} active`}
  //     />
  //   ),
  // },
  // {
  //   title: "Roles",
  //   dataIndex: "annualTimeSaved",
  //   key: "annualTimeSaved",
  //   render: (_: string, record: Tenant) => (
  //     <div
  //       className="text-base font-normal leading-6 truncate w-auto"
  //       title={record.totalRoles.toString()}
  //     >
  //       {record.totalRoles}
  //     </div>
  //   ),
  // },
  // {
  //   title: "File Imported",
  //   dataIndex: "fileImported",
  //   key: "fileImported",
  //   render: (_: string, record: Tenant) => (
  //     <RowSection
  //       isTextRow
  //       value={record.isImported ? "Yes" : "No"}
  //       description={`on ${record.importDate}`}
  //     />
  //   ),
  // },
  {
    title: "Actions",
    key: "actions",
    width: 120,
    render: () => (
      <DetailActions
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    ),
  },
];
