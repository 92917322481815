import { Admin } from "./types";

export const mockAdmins: Admin[] = [
  {
    email: "admin1@example.com",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin2@example.com",
    role: "Blue Admin",
    managedTenants: ["Tenant A", "Tenant B"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com",
    role: "Blue Admin",
    managedTenants: ["Tenant A", "Tenant B", "Tenant C", "Tenant D"],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin1@example.com.vn",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin2@example.com.vn",
    role: "Blue Admin",
    managedTenants: ["Tenant A", "Tenant B"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com.vn",
    role: "Blue Admin",
    managedTenants: ["Tenant A", "Tenant B", "Tenant C", "Tenant D"],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin1@example.com.us",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin2@example.com.us",
    role: "Blue Admin",
    managedTenants: ["Tenant A", "Tenant B"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com.us",
    role: "Blue Admin",
    managedTenants: [
      "Tenant A",
      "Tenant B",
      "Tenant C",
      "Tenant D",
      "Tenant A1",
      "Tenant B1",
      "Tenant C1",
      "Tenant D1",
      "Tenant A2",
      "Tenant B2",
      "Tenant C2",
      "Tenant D2",
    ],
    status: "Active",
    inviteState: "Active",
  },
];
