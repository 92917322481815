import React from "react";
import { Card, Col, Row } from "antd";
import { AIExposure } from "models/opportunityModel";
import formatNumber from "utils/formatNumber";

import InfoCardWithNumber from "./InfoCardWithNumber";
import InfoCard from "./InfoCard";
import Tags from "./Tags";

interface Props {
  totalTeams: number;
  totalJobs: number;
  totalTasks: number;
  aiExposure: AIExposure;
  annualProdGain: number;
  annualTimeSaved: number;
}

const InfoScreen: React.FC<Props> = (props) => {
  const {
    totalTeams,
    totalTasks,
    totalJobs,
    aiExposure,
    annualProdGain,
    annualTimeSaved,
  } = props;
  const { amount: hours, currency: hourCurrency } = formatNumber(
    aiExposure.totalHours
  );
  const { amount: employees, currency: employeeCurrency } = formatNumber(
    aiExposure.totalEmployees
  );
  return (
    <Card
      className="h-full w-full p-0 bg-transparent text-white font-thin !shadow-none"
      bordered={false}
    >
      <Row className="flex items-center">
        <Col className="text-5xl font-black leading-10">
          Opportunity for AI Adoption
        </Col>
      </Row>
      <Row className="pt-8 pb-4">
        <Col className="text-xl flex items-center">
          <Tags
            teamsCount={totalTeams}
            jobsCount={totalJobs}
            tasksCount={totalTasks}
          />
        </Col>
      </Row>
      <Row gutter={20} className="pt-3">
        <Col span={8}>
          <InfoCard
            value={aiExposure.percent.toFixed(1)}
            currency="%"
            text={"AI Exposure"}
            description={`A total of ${employees}${employeeCurrency} employees across ${aiExposure.totalRoles} role(s) have the potential to have ${hours}${hourCurrency} hours of time savings for ${aiExposure.totalTasks} tasks.`}
          />
        </Col>
        <Col span={8}>
          <InfoCardWithNumber
            value={annualProdGain}
            text={"Annual Productivity Gain"}
            isViewCurrency={true}
            description={
              "Your people collaborating with AI can perform their jobs faster and free up time for valuable work."
            }
          />
        </Col>
        <Col span={8}>
          <InfoCardWithNumber
            value={annualTimeSaved}
            text={"Saved Hours"}
            description={
              "Across your workforce, this is the total amount of hours that can be saved by augmenting your people."
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default InfoScreen;
