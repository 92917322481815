import React, { useState } from "react";
import { Row } from "antd";
import { ExploreRole } from "models/workforceModel";
import SwitchGroupPanel, { PanelConfig } from "components/SwitchGroupPanel";
import formatNumber from "utils/formatNumber";

import TaskImpact from "../TeamScreen/TaskImpact";
import ProductivityGain from "../TeamScreen/ProductivityGain";
import AiOpportunity from "../TeamScreen/AiOpportunity";

interface Props extends ExploreRole {}

const RoleSection: React.FC<Props> = ({ ...role }: ExploreRole) => {
  const [view, setView] = useState<React.ReactNode>(
    <AiOpportunity
      data={role.aiOpportunities}
      text={"Work breakdown for this role:"}
    />
  );
  const generateScreen = (screen: string) => {
    switch (screen) {
      case "gain": {
        return setView(
          <ProductivityGain data={role.productivityGainByLevels} />
        );
      }
      case "task": {
        return setView(
          <TaskImpact data={role.impactedTasks} isViewHeader={false} />
        );
      }
      case "adoption": {
        return setView(
          <div className="flex items-center justify-center text-center h-60 bg-gray75 text-5xl text-gray400 font-black w-full">
            <div className="pb-4">Coming soon</div>
          </div>
        );
      }
      default: {
        return setView(
          <AiOpportunity
            data={role.aiOpportunities}
            text={"Work breakdown for this role:"}
          />
        );
      }
    }
  };

  const handleModeChange = (value: string) => {
    generateScreen(value);
  };

  const generatePanelData = () => {
    const { amount: employees } = formatNumber(role.totalEmployees);
    const tabConfig: PanelConfig[] = [
      {
        mode: "opportunity",
        label: "GENERATIVE AI OPPORTUNITY",
        value: role.monthlyTimeSaved,
        isViewCurrency: false,
        isGradient: true,
        description: "hour(s)/month",
      },
      {
        mode: "gain",
        label: "PRODUCTIVITY GAIN",
        value: role.annualProdGain,
        isViewCurrency: true,
        isGradient: true,
        description: "anually",
      },
      {
        mode: "task",
        label: "TASKS IMPACTED",
        value: role.taskImpacted,
        isViewCurrency: false,
        isGradient: true,
        description: `across ${employees} employee(s)`,
      },
      {
        isHideTab: true,
        mode: "",
        value: 0,
        label: "",
        description: "",
      },
    ];

    return tabConfig;
  };

  const panels = generatePanelData();

  return (
    <div className="h-full w-full p-0">
      <Row>
        <SwitchGroupPanel
          onModeChange={handleModeChange}
          panelConfig={panels}
        />
      </Row>
      <Row className="pt-14">{view}</Row>
    </div>
  );
};

export default RoleSection;
