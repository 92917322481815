import { isEmpty } from "lodash";
import { useState, useEffect, useMemo, useRef } from "react";

interface UseTabStateParams<T> {
  initialItems: T[];
  selectedItems?: T[];
  pageSize?: number;
}

export const useTabState = <T extends { id: string }>({
  initialItems = [],
  selectedItems = [],
  pageSize: initialPageSize = 9,
}: UseTabStateParams<T>) => {
  const [filterOption, setFilterOption] = useState<string>("allItems");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize); // Added pageSize state
  const [selectedIds, setSelectedIds] = useState<Set<string>>(
    new Set((selectedItems || []).map((item) => item.id))
  );
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isTableView, setIsTableView] = useState<boolean>(false);
  const initialSelectedItems = useRef<Set<string>>(new Set(selectedIds));

  useEffect(() => {
    if (!isEmpty(selectedItems)) {
      const selectedSet = new Set(selectedItems.map((item) => item.id));
      setSelectedIds(selectedSet);
      initialSelectedItems.current = new Set(selectedSet);
    }
  }, [selectedItems]);

  const mergedList = useMemo(() => {
    const selectedMap = new Map<string, T>(
      (selectedItems || []).map((item) => [item.id, item])
    );

    return initialItems.map((item) => ({
      ...item,
      isSelected: selectedMap.has(item.id),
    }));
  }, [initialItems, selectedItems]);

  const handleFilterChange = (value: string) => {
    setFilterOption(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemSelection = (id: string) => {
    setSelectedIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }

      const isEqual = (a: Set<string>, b: Set<string>) =>
        a.size === b.size && [...a].every((value) => b.has(value));

      setIsDirty(!isEqual(newSelected, initialSelectedItems.current));
      return newSelected;
    });
  };

  const toggleViewMode = () => {
    setIsTableView((prev) => !prev);
  };

  const filteredList = useMemo(() => {
    return filterOption === "selectedItems"
      ? mergedList.filter((item) => selectedIds.has(item.id))
      : mergedList;
  }, [filterOption, mergedList, selectedIds]);

  const paginatedList = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [filteredList, currentPage, pageSize]);

  return {
    filterOption,
    setFilterOption,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    selectedIds,
    setSelectedIds,
    isDirty,
    setIsDirty,
    isTableView,
    toggleViewMode,
    initialSelectedItems,
    mergedList,
    filteredList,
    paginatedList,
    handleFilterChange,
    handlePageChange,
    handleItemSelection,
  };
};

export default useTabState;
