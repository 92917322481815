import React from "react";
import { Space, Alert } from "antd";

interface Props {
  message: string;
  className?: string;
}

const ErrorAlert: React.FC<Props> = ({ message, className }) => {
  return (
    <Space direction="vertical" className="w-full pb-2">
      <Alert
        showIcon={true}
        message={message}
        type="error"
        className={`text-red-500 ${className || ""}`}
      />
    </Space>
  );
};

export default ErrorAlert;
