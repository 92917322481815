import React, { FC } from "react";
import { Card, Row } from "antd";
import { SwitchGroupButton } from "components/Button";
import {
  TeamIcon as OriginalTeamIcon,
  UserCircleIcon as OriginalUserCircleIcon,
} from "components/Icon";
import { TabConfig } from "components/Button/SwitchGroupButton";

interface Props {
  onModeChange: (value: string) => void;
  defaultValue: string;
}

interface IconProps {
  fill: string;
}

const TeamIcon: FC<IconProps> = ({ fill }) => <OriginalTeamIcon fill={fill} />;
const UserCircleIcon: FC<IconProps> = ({ fill }) => (
  <OriginalUserCircleIcon fill={fill} />
);

const TopScreen: React.FC<Props> = ({ onModeChange, defaultValue }) => {
  const handleModeChange = (value: string) => {
    onModeChange(value);
  };

  const tabConfig: TabConfig[] = [
    {
      value: "team",
      label: "Explore Teams",
      icon: TeamIcon,
    },
    {
      value: "role",
      label: "Explore Roles",
      icon: UserCircleIcon,
    },
  ];

  return (
    <Card className="h-full w-full p-0 !shadow-none" bordered={false}>
      <Row className="flex items-center pb-2">
        <SwitchGroupButton
          onModeChange={handleModeChange}
          tabConfig={tabConfig}
          defaultValue={defaultValue}
        />
      </Row>
    </Card>
  );
};

export default TopScreen;
