import React from "react";
import { Button, Tooltip } from "antd";
import { ListIcon, GridIcon } from "components/Icon";
interface ViewSwitcherProps {
  isTableView: boolean;
  toggleViewMode: () => void;
}

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  isTableView,
  toggleViewMode,
}) => {
  return (
    <div className="flex items-center">
      <Tooltip title="Table View">
        <Button
          type="text"
          icon={<ListIcon isLightMode={isTableView} />}
          onClick={toggleViewMode}
          className="mr-4"
        />
      </Tooltip>
      <Tooltip title="Card View">
        <Button
          type="text"
          icon={<GridIcon isLightMode={!isTableView} />}
          onClick={toggleViewMode}
        />
      </Tooltip>
    </div>
  );
};

export default ViewSwitcher;
