import React from "react";
import { Row, Col, Card } from "antd";
import { OpportunityTeam } from "models/opportunityModel";
import { AugmentationSection, MetricsSection } from "components/Section";
import formatNumber from "utils/formatNumber";
import { CHART_COLORS_STYLES } from "common/constants";

interface Props extends OpportunityTeam {}

const TeamContent: React.FC<Props> = ({
  totalTasks,
  totalEmployees,
  totalRoles,
  annualProdGain,
  taskImpacted,
  monthlyTimeSaved = 6,
  workBreakdowns = [],
}) => {
  const { amount: roles } = formatNumber(totalRoles);
  const percentTask = ((taskImpacted * 100) / totalTasks).toFixed(0);
  return (
    <div className="pt-4 pb-4">
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card bordered>
            <MetricsSection
              title={"Productivity Gain"}
              value={annualProdGain}
              isViewCurrency
              isGradient
              description={"per year"}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered>
            <MetricsSection
              title={"GenAI Opportunity"}
              value={monthlyTimeSaved}
              description={"hours per month"}
              isGradient
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered>
            <MetricsSection
              title={"Total Employees"}
              value={totalEmployees}
              description={`in ${roles} role(s)`}
              isGradient
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered>
            <MetricsSection
              title={"Tasks Impacted"}
              value={taskImpacted}
              description={`${percentTask}% of ${totalTasks} task(s)`}
              isGradient
            />
          </Card>
        </Col>
      </Row>
      <Row className="pb-6">
        <p className="text-2xl text-black font-light pt-7 leading-8">
          Work breakdown for this team:
        </p>
        <p className="text-lg text-black font-normal pt-2 leading-7">
          Our analysis distributes the total generative AI opportunity into the
          categories of Expertise (knowledge-centric), People (human-centric)
          and Routine (transactional-oriented).
        </p>
      </Row>
      <Row gutter={[32, 0]}>
        {workBreakdowns.map((item, index) => (
          <Col span={8} key={index}>
            <AugmentationSection
              {...item}
              color={CHART_COLORS_STYLES[index % CHART_COLORS_STYLES.length]}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TeamContent;
