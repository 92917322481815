import React from "react";
import { Slider, ConfigProvider } from "antd";

interface Props {
  filterHours: number;
  filterMaxHours: number;
  onFilterChange: (value: number) => void;
}

const FilterSlider: React.FC<Props> = ({
  filterHours,
  filterMaxHours,
  onFilterChange,
}) => {
  const theme = {
    components: {
      Slider: {
        handleLineWidth: 5,
        handleLineWidthHover: 5,
        railSize: 8,
      },
    },
    token: {},
  };

  return (
    <ConfigProvider theme={theme}>
      <>
        <div className="pb-2 text-lg font-bold leading-7">
          <span>Minimum: </span>
          <span className="text-purple900">{filterHours} hour(s)</span>
        </div>
        <Slider
          defaultValue={filterHours}
          max={filterMaxHours}
          onChange={onFilterChange}
        />
      </>
    </ConfigProvider>
  );
};

export default FilterSlider;
