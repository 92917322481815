import React, { useState } from "react";
import { Row } from "antd";
import { ExploreTeam } from "models/workforceModel";
import SwitchGroupPanel, { PanelConfig } from "components/SwitchGroupPanel";

import TaskImpact from "./TaskImpact";
import AugmentedRoles from "./AugmentedRoles";
import ProductivityGain from "./ProductivityGain";
import AiOpportunity from "./AiOpportunity";

interface Props extends ExploreTeam {}

const TeamSection: React.FC<Props> = ({ ...team }: ExploreTeam) => {
  const [view, setView] = useState<React.ReactNode>(
    <AiOpportunity
      data={team.aiOpportunities}
      text={"Work breakdown for this team:"}
    />
  );
  const generateScreen = (screen: string) => {
    switch (screen) {
      case "gain": {
        return setView(
          <ProductivityGain data={team.productivityGainByLevels} />
        );
      }
      case "augment": {
        return setView(<AugmentedRoles tableData={team.roles} />);
      }
      case "task": {
        return setView(
          <TaskImpact data={team.impactedTasks} isViewTeam={false} />
        );
      }
      default: {
        return setView(
          <AiOpportunity
            data={team.aiOpportunities}
            text={"Work breakdown for this team:"}
          />
        );
      }
    }
  };

  const handleModeChange = (value: string) => {
    generateScreen(value);
  };

  const generatePanelData = () => {
    const tabConfig: PanelConfig[] = [
      {
        mode: "opportunity",
        label: "GENERATIVE AI OPPORTUNITY",
        value: team.monthlyTimeSaved,
        isViewCurrency: false,
        isGradient: true,
        description: "hour(s)/month",
      },
      {
        mode: "gain",
        label: "PRODUCTIVITY GAIN",
        value: team.annualProdGain,
        isViewCurrency: true,
        isGradient: true,
        description: "anually",
      },
      {
        mode: "task",
        label: "TASKS IMPACTED",
        value: team.taskImpacted,
        isViewCurrency: false,
        isGradient: true,
        description: `of ${team.totalTasks} task(s)`,
      },
      {
        mode: "augment",
        label: "AUGMENTED ROLES",
        value: team.totalAugmentedRoles,
        isViewCurrency: false,
        isGradient: true,
        description: `of ${team.totalRoles} role(s)`,
      },
    ];

    return tabConfig;
  };

  const panels = generatePanelData();

  return (
    <div className="h-full w-full p-0">
      <Row>
        <SwitchGroupPanel
          onModeChange={handleModeChange}
          panelConfig={panels}
        />
      </Row>
      <Row className="pt-14">{view}</Row>
    </div>
  );
};

export default TeamSection;
