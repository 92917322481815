import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "common/store";
import { FormContainer } from "components/Layout";
import { resetPassword, validateResetPwd } from "services/authService";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery } from "hooks";
import { ErrorForm, SuccessForm } from "components/Form";
import Loader from "components/Loading/Loader";
import { ErrorSpace } from "components/Alert";

import AuthLogo from "../AuthLogo";
import AuthCode from "../AuthCode";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const isReset = useSelector((state) => state.auth.isReset);
  const isResetValid = useSelector((state) => state.auth.isResetValid);

  const [form] = Form.useForm();
  const code = useQuery().get("code") as string;
  const email = useQuery().get("email") as string;

  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetExpired, setResetExpired] = useState(false);

  useEffect(() => {
    const effect = async () => {
      if (!code || !email) {
        navigate("/login");
      } else if (!resetExpired) {
        dispatch(validateResetPwd({ email, resetPasswordToken: code }));
        setResetExpired(true);
      }
      if (isReset && !error && !loading) {
        setResetSuccess(true);
      }
    };
    effect();
  }, [code, email, isReset, navigate]);

  const onFinish = useCallback(
    (values: { email: string; password: string; confirmPassword: string }) => {
      dispatch(
        resetPassword({
          code,
          password: values.password,
          passwordConfirmation: values.confirmPassword,
        })
      );
    },
    []
  );

  if (loading) {
    return <Loader />;
  }

  if (!isResetValid) {
    return (
      <FormContainer>
        <AuthLogo />
        <ErrorForm error={error} />
      </FormContainer>
    );
  }

  return (
    <FormContainer className="pt-5">
      <AuthLogo />
      {error && <ErrorSpace message={error} />}
      {resetSuccess ? (
        <SuccessForm text={"Your password has been updated."} />
      ) : (
        <>
          <AuthCode
            code={code}
            content={"You received this in your work email."}
            title={"Password Reset Code"}
          />
          <ResetPasswordForm
            email={email}
            form={form}
            onFinish={onFinish}
            loading={loading}
          />
        </>
      )}
    </FormContainer>
  );
};

export default ResetPassword;
