import React from "react";

import { IconProps } from "./model";
const RoadMapIcon: React.FC<IconProps> = () => (
  <span className="ant-menu-item-icon">
    <svg width="96" height="74" viewBox="0 0 96 74" fill="none">
      <g clipPath="url(#clip0_1926_3952)">
        <path
          d="M96 39.4172C96 41.8246 95.5229 43.6463 95.2301 44.503C92.53 52.6358 87.2165 61.9832 79.4415 72.2848L78.8018 73.1197L78.162 72.2848C70.452 62.0699 65.1494 52.7659 62.3951 44.6114C62.1023 43.7222 61.6035 41.8679 61.6035 39.4172C61.6035 29.9289 69.3134 22.219 78.8018 22.219C88.2901 22.219 95.9891 29.9289 95.9891 39.4172H96ZM93.712 44.0042C93.9722 43.2234 94.406 41.6077 94.406 39.4172C94.406 30.8073 87.4009 23.813 78.8126 23.813C70.2243 23.813 63.2084 30.8181 63.2084 39.4172C63.2084 41.6511 63.653 43.3102 63.9349 44.1017C66.5483 51.8659 71.5473 60.747 78.8234 70.4847C86.1538 60.6602 91.1637 51.7575 93.7336 44.0042H93.712Z"
          fill="#46D9FF"
        />
        <path
          d="M78.8124 29.2024C83.8873 29.2024 88.0296 33.3339 88.0296 38.4196C88.0296 43.5053 83.8873 47.6368 78.8124 47.6368C73.7375 47.6368 69.5952 43.4945 69.5952 38.4196C69.5952 33.3447 73.7267 29.2024 78.8124 29.2024ZM86.4356 38.4305C86.4356 34.2231 83.009 30.8073 78.8124 30.8073C74.6159 30.8073 71.1893 34.2231 71.1893 38.4305C71.1893 42.6378 74.605 46.0536 78.8124 46.0536C83.0198 46.0536 86.4356 42.6378 86.4356 38.4305Z"
          fill="#46D9FF"
        />
        <path
          d="M79.6042 5.50864V17.5995C79.6042 18.0332 79.2464 18.3911 78.8126 18.3911C78.3789 18.3911 78.021 18.0332 78.021 17.5995V14.4439H1.59404V57.407C1.59404 59.5757 3.36157 61.3433 5.53033 61.3433H64.0542C64.488 61.3433 64.8458 61.7011 64.8458 62.1349C64.8458 62.5686 64.488 62.9265 64.0542 62.9265H5.51949C2.47238 62.9265 0 60.4541 0 57.4178V5.51949C0 2.48323 2.48323 0 5.51949 0H74.0847C77.1318 0 79.5934 2.46154 79.5934 5.50864H79.6042ZM78.0102 12.8499V5.51949C78.0102 3.36157 76.2426 1.59404 74.0739 1.59404H5.50864C3.33989 1.59404 1.58319 3.35073 1.58319 5.51949V12.8607H77.9993L78.0102 12.8499Z"
          fill="#1B396F"
        />
        <path
          d="M51.0743 24.8647C51.5081 24.8647 51.8659 25.2226 51.8659 25.6563C51.8659 26.0901 51.5081 26.4479 51.0743 26.4479H13.0777C12.644 26.4479 12.2861 26.1009 12.2861 25.6563C12.2861 25.2117 12.644 24.8647 13.0777 24.8647H51.0743Z"
          fill="#1B396F"
        />
        <path
          d="M51.0743 36.0015C51.5081 36.0015 51.8659 36.3485 51.8659 36.7931C51.8659 37.2377 51.5081 37.5847 51.0743 37.5847H13.0777C12.644 37.5847 12.2861 37.2268 12.2861 36.7931C12.2861 36.3593 12.644 36.0015 13.0777 36.0015H51.0743Z"
          fill="#1B396F"
        />
        <path
          d="M51.0743 47.1379C51.5081 47.1379 51.8659 47.4849 51.8659 47.9295C51.8659 48.3741 51.5081 48.7211 51.0743 48.7211H13.0777C12.644 48.7211 12.2861 48.3633 12.2861 47.9295C12.2861 47.4958 12.644 47.1379 13.0777 47.1379H51.0743Z"
          fill="#1B396F"
        />
        <path
          d="M21.8392 6.49536C22.5007 6.49536 23.032 7.02671 23.032 7.67733C23.032 8.32796 22.5007 8.87015 21.8392 8.87015C21.1777 8.87015 20.6572 8.3388 20.6572 7.67733C20.6572 7.01586 21.1886 6.49536 21.8392 6.49536Z"
          fill="#46D9FF"
        />
        <path
          d="M15.4199 6.49536C16.0705 6.49536 16.6018 7.02671 16.6018 7.67733C16.6018 8.32796 16.0705 8.87015 15.4199 8.87015C14.7692 8.87015 14.2271 8.3388 14.2271 7.67733C14.2271 7.01586 14.7584 6.49536 15.4199 6.49536Z"
          fill="#46D9FF"
        />
        <path
          d="M8.97885 6.49536C9.64032 6.49536 10.1717 7.02671 10.1717 7.67733C10.1717 8.32796 9.64032 8.87015 8.97885 8.87015C8.31738 8.87015 7.79688 8.3388 7.79688 7.67733C7.79688 7.01586 8.31738 6.49536 8.97885 6.49536Z"
          fill="#46D9FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_3952">
          <rect width="96" height="73.1305" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default RoadMapIcon;
