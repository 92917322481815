import { notification } from "antd";
import { useDispatch } from "common/store";
import { useEffect, useRef } from "react";
import { logoutSuccess } from "slices/authSlice";

const logoutTimeout =
  Number.parseInt(process.env.REACT_APP_LOGOUT_TIMEOUT_MINUTES || "60") *
  60_000; // Convert minutes to milliseconds

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const handleLogout = () => {
    dispatch(logoutSuccess());
  };

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      const currentUrl = window.location.href;
      const isLoginPage = currentUrl.includes("/login");
      if (!isLoginPage) {
        handleLogout();
        notification.info({
          message: "Logged Out",
          description: "You have been logged out due to inactivity.",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }, logoutTimeout);
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll"];

    const reset = () => {
      resetTimer();
    };

    for (const event of events) {
      window.addEventListener(event, reset);
    }

    resetTimer();

    return () => {
      for (const event of events) {
        window.removeEventListener(event, reset);
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);
};

export default useAutoLogout;
