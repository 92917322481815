import React from "react";
import { Row, Col, Card } from "antd";

interface Props {
  value: string | number;
  text: string;
  description: string;
  currency: string;
}

const InfoCard: React.FC<Props> = ({ value, text, description, currency }) => {
  return (
    <Card
      bordered={false}
      className="bg-transparent text-white text-gradient-lightPurple min-h-60"
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <div className="text-7xl">
            <span className="font-bold">
              {""}
              {value}
            </span>
            {currency && <span className="font-thin">{currency}</span>}
          </div>
        </Col>
        <Col span={24}>
          <div className=" text-xl font-bold leading-10">{text}</div>
        </Col>
        <Col span={24} className="min-h-[72px]">
          <div className="text-lg font-light leading-7">{description}</div>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
