import React from "react";
import theme from "assets/styles/theme";
import {
  CheckboxIcon,
  CompassIcon,
  PencilSimpleIcon,
  TeamIcon,
  UserCircleIcon,
} from "components/Icon";
import { ListItem } from "models/roadMapModel";
import formatNumber from "utils/formatNumber";

interface Props extends ListItem {
  onClick: () => void;
}

const InfoRow: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: number;
}> = ({ icon, label, value }) => {
  const { amount } = formatNumber(value);
  return (
    <div className="flex items-center pb-2">
      <span className="pr-2 w-10 opacity-50">{icon}</span>
      <span>
        <span className="text-base font-semibold leading-6">{amount}</span>{" "}
        <span className="text-base font-semibold leading-6">{label}</span>
      </span>
    </div>
  );
};

const StatusIcon: React.FC<{ status: string }> = ({ status }) => {
  const iconProps = {
    fill: theme.color.icon.black,
    width: 32,
    height: 32,
  };

  return (
    <div className="flex items-center">
      {status === "Draft" ? (
        <>
          <PencilSimpleIcon {...iconProps} />
          <span className="pl-2 text-sm font-bold leading-5 text-left">
            DRAFT
          </span>
        </>
      ) : (
        <>
          <CheckboxIcon {...iconProps} />
          <span className="pl-2 text-sm font-bold leading-5 text-left">
            COMPLETE
          </span>
        </>
      )}
    </div>
  );
};

const ListSection: React.FC<Props> = ({
  name,
  totalTeams,
  totalEmployees,
  status,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="p-4 my-2 mr-4 cursor-pointer rounded-md text-center transition-all duration-300 shadow-gradient border border-gray900t2 bg-white text-black"
    >
      <div className="h-12 flex justify-between items-center">
        {/* FIXME: hide this feature will open next sprint - remove  hidden */}
        <div className="hidden">
          <StatusIcon status={status} />
        </div>
        <CompassIcon fill={theme.color.icon.black} width={32} height={32} />
      </div>
      <div
        title={name}
        className="text-2xl font-black leading-8 text-left truncate !text-gradient-base text-gradient-purple pb-4"
      >
        {name}
      </div>
      <InfoRow
        icon={<TeamIcon fill={theme.color.icon.black} width={32} height={32} />}
        label="Team(s)"
        value={totalTeams}
      />
      <InfoRow
        icon={
          <UserCircleIcon
            fill={theme.color.icon.black}
            width={32}
            height={32}
          />
        }
        label="Employee(s)"
        value={totalEmployees}
      />
    </div>
  );
};

export default ListSection;
