import { gql } from "@apollo/client/core";

const UPSERT_ROADMAP = gql`
  mutation UpsertRoadmap($input: UpsertRoadmapInput!) {
    upsertRoadmap(input: $input) {
      id
      name
    }
  }
`;

const GET_ROADMAPS = gql`
  query ListRoadmap {
    listRoadmap {
      id
      name
      totalEmployees
      totalTeams
    }
  }
`;

const GET_ROADMAP_BY_ID = gql`
  query GetRoadmap($id: Int!) {
    getRoadmap(id: $id) {
      name
      type
      status
      completedSteps
    }
  }
`;

const GET_ALL_TEAMS = gql`
  query GetAllTeams {
    getTeams {
      id
      name
      totalEmployees
      monthlyTimeSaved
    }
  }
`;

const GET_SELECTED_TEAMS = gql`
  query GetRoadmap($id: Int!) {
    getRoadmap(id: $id) {
      teams
    }
  }
`;

const GET_ALL_TASKS = gql`
  query GetTasksInRoadmap($roadmapId: ID!) {
    getTasks(roadmapId: $roadmapId) {
      id
      description
      totalEmployees
      totalRoles
      monthlyTimeSaved
    }
  }
`;

const GET_SELECTED_TASKS = gql`
  query GetRoadmap($id: Int!) {
    getRoadmap(id: $id) {
      tasks
    }
  }
`;

const GET_ALL_ROLES = gql`
  query GetRolesInRoadmap($roadmapId: ID!) {
    getRoles(roadmapId: $roadmapId) {
      id
      name
      totalEmployees
      monthlyTimeSaved
    }
  }
`;

const GET_SELECTED_ROLES = gql`
  query GetRoadmap($id: Int!) {
    getRoadmap(id: $id) {
      roles
    }
  }
`;

const GET_SUMMARY = gql`
  query GetRoadmapSummary($roadmapId: ID!) {
    getSummary(roadmapId: $roadmapId) {
      percent
      monthlyTimeSaved
      otherRoadmapsMonthlyTimeSaved
      organizationMonthlyTimeSaved
    }
  }
`;

export {
  UPSERT_ROADMAP,
  GET_ROADMAPS,
  GET_ROADMAP_BY_ID,
  GET_ALL_TEAMS,
  GET_SELECTED_TEAMS,
  GET_ALL_TASKS,
  GET_SELECTED_TASKS,
  GET_ALL_ROLES,
  GET_SELECTED_ROLES,
  GET_SUMMARY,
};
