import theme from "assets/styles/theme";
import { IconProps } from "components/Icon/model";
import React from "react";

interface Props {
  title: string;
  description: string;
  icon: React.FC<IconProps>;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

const TypeSection: React.FC<Props> = ({
  title,
  description,
  icon: Icon,
  isActive,
  isDisabled,
  onClick,
}) => {
  const fillColor = isActive
    ? theme.color.icon.default
    : theme.color.icon.black;

  return (
    <div
      onClick={onClick}
      className={`h-44 p-4 my-4 mr-4 cursor-pointer rounded-md transition-all duration-300 shadow-gradient border border-gray900t2 ${
        isActive ? "bg-black text-white" : "bg-white text-black"
      } ${isDisabled ? "!cursor-not-allowed opacity-50" : ""}`}
    >
      <div className="h-12 flex justify-between items-center pb-2">
        <Icon fill={fillColor} />
      </div>
      <div className="text-base leading-6 font-bold pb-2">{title}</div>
      <div className="font-normal">{description}</div>
    </div>
  );
};

export default TypeSection;
