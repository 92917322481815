import React, { useState } from "react";
import { Col, Row } from "antd";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";
import getAllParams from "utils/getUrlParams";
import isEmpty from "lodash/isEmpty";
import { useSearchParams } from "react-router-dom";

import TopScreen from "./TopScreen";
import CreateRoadMap from "./CreateRoadMap";
import RoadMapList from "./RoadMapList";

const Roadmap: React.FC = () => {
  const allParams = getAllParams(["create", "list"]);
  const defaultMode = isEmpty(allParams) ? "create" : allParams[0];
  const [mode, setMode] = useState<string>(defaultMode);
  const [, setSearchParams] = useSearchParams();

  const handleModeChange = (value: string) => {
    setMode(value);
    const params = new URLSearchParams();
    params.set(value, "");
    setSearchParams(params);
  };

  return (
    <MainLayout>
      <CardLayout>
        <BoxLayout className="bg-white shadow-gradient">
          <Col span={24}>
            <Row className="bg-transparent rounded-3xl">
              <TopScreen onModeChange={handleModeChange} defaultValue={mode} />
            </Row>
            <Row className="pl-7">
              {mode === "list" ? <RoadMapList /> : <CreateRoadMap />}
            </Row>
          </Col>
        </BoxLayout>
      </CardLayout>
    </MainLayout>
  );
};

export default Roadmap;
