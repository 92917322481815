import React, { useState, useEffect } from "react";
import { GraphIcon, UserCircleIcon } from "components/Icon";
import formatNumber from "utils/formatNumber";
import theme from "assets/styles/theme";
import Checkbox from "components/Checkbox";

interface Props {
  tabKey: string;
  id: string;
  title: string;
  icon1?: React.ReactNode;
  icon2?: React.ReactNode;
  monthlyHours: number;
  totalEmployees: number;
  isActive: boolean;
  isTabCompleted?: boolean;
  onCheck: () => void;
  onCardClick: (tabKey: string, id: string) => void;
}

const CardView: React.FC<Props> = ({
  tabKey,
  id,
  title,
  icon1 = <GraphIcon fill={theme.figmaColor.sky300} width={32} height={32} />,
  icon2 = (
    <UserCircleIcon fill={theme.figmaColor.sky300} width={32} height={32} />
  ),
  monthlyHours,
  totalEmployees,
  isActive = false,
  isTabCompleted = false,
  onCheck,
  onCardClick,
}) => {
  const [isCompleted, setIsCompleted] = useState(isActive);

  useEffect(() => {
    setIsCompleted(isActive);
  }, [isActive]);

  const handleCheckClick = () => {
    if (isTabCompleted) {
      onCardClick(tabKey, id);
    } else {
      setIsCompleted((prev) => !prev);
      onCheck();
    }
  };

  const renderIconWithText = (
    icon: React.ReactNode,
    text: string,
    label: string
  ) => (
    <div className="flex items-center pb-2">
      <span className="pr-2 w-10">{icon}</span>
      <span>
        <span className="text-xl font-semibold leading-7">{text}</span>
        <span className="text-base font-normal leading-6"> {label}</span>
      </span>
    </div>
  );

  const { amount: formattedHours } = formatNumber(monthlyHours);
  const { amount: formattedEmployees } = formatNumber(totalEmployees);

  const activeClassNames = isCompleted
    ? "border-2 border-gray900"
    : "border-1 border-gray200";

  return (
    <div
      onClick={handleCheckClick}
      className={`shadow-gray p-4 my-4 mr-4 mt-0 cursor-pointer rounded-md text-center transition-all duration-300 border ${activeClassNames}`}
    >
      <div className="h-12 flex justify-between items-center pb-4">
        <div
          title={title}
          className="text-base font-bold line-clamp-2 text-left"
        >
          {title}
        </div>
        <div className="float-right">
          <Checkbox isChecked={isCompleted} />
        </div>
      </div>
      {renderIconWithText(icon1, formattedHours, "hours/mo.")}
      {renderIconWithText(icon2, formattedEmployees, "employees")}
    </div>
  );
};

export default CardView;
