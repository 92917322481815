import React, { useState, useRef } from "react";
import { Carousel as AntdCarousel } from "antd";
import { CarouselRef } from "antd/es/carousel";

interface Props {
  children: React.ReactNode[];
}

const Carousel: React.FC<Props> = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
    if (carouselRef.current) {
      carouselRef.current.goTo(index);
    }
  };

  return (
    <>
      <div className="w-full bg-transparent h-8 flex items-center pb-2.5">
        <div className="bg-gray200 w-full h-[1px]"></div>
      </div>
      <AntdCarousel
        effect={"fade"}
        dotPosition="top"
        arrows
        infinite={false}
        className="pt-0"
        ref={carouselRef}
        afterChange={(current) => setCurrentSlide(current)}
      >
        {children}
      </AntdCarousel>
      <div>
        <div className="absolute top-0 left-0 p-4 pb-10 flex z-10 space-x-0.5 bg-white">
          {children.map((_, index) => (
            <React.Fragment key={index}>
              <button
                onClick={() => handleDotClick(index)}
                className={`p-2 px-4 text-sm ${
                  index === 0
                    ? "rounded-tl-2xl rounded-bl-2xl"
                    : index === children.length - 1
                    ? "rounded-tr-2xl rounded-br-2xl"
                    : "border-t-2 border-transparent"
                } ${
                  index === currentSlide
                    ? "bg-black text-white"
                    : "bg-gray75 text-black"
                }`}
              >
                {index + 1}
              </button>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;
