import { gql } from "@apollo/client/core";

const GET_OPPORTUNITIES = gql`
  query {
    getAIOpportunity {
      totalTeams
      totalJobs
      totalTasks
      aiExposure {
        percent
        totalEmployees
        totalRoles
        totalTasks
        totalHours
      }
      annualProdGain
      annualTimeSaved
      teams {
        name
        totalEmployees
        totalRoles
        taskImpacted
        totalTasks
        annualProdGain
        monthlyTimeSaved
        workBreakdowns {
          title
          type
          monthlyTimeSaved
          description
          chartPortionValues
          topAreas
          chartPercent
        }
      }
      roles {
        title
        totalEmployees
        totalTeams
        annualProdGain
        annualTimeSaved
      }
      adoption {
        annualProdGain
        totalEmployees
        annualHours
        annualTimeSaved
      }
    }
  }
`;

const GET_ADOPTION_SLIDER = gql`
  query GetUserPreference {
    getUserPreference {
      adoptionSlider
    }
  }
`;

const SET_ADOPTION_SLIDER = gql`
  mutation SetUserPreference($setUserPreferenceInput: SetUserPreferenceInput!) {
    setUserPreference(input: $setUserPreferenceInput) {
      adoptionSlider
    }
  }
`;

export { GET_OPPORTUNITIES, GET_ADOPTION_SLIDER, SET_ADOPTION_SLIDER };
