import React from "react";
import { Form, Col, Row, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { PwdInput, TextInput, TrimInput } from "components/Input";
import { SubmitButton } from "components/Button";

import { authRules } from "../validationRules";

interface FormProps {
  onFinish: (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => void;
  loading: boolean;
  email: string;
  form: FormInstance;
}

const GetStartedForm: React.FC<FormProps> = ({
  onFinish,
  loading,
  form,
  email,
}) => {
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      requiredMark={false}
      name="getStarted"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      className="w-full"
      layout="vertical"
    >
      <Form.Item
        name="email"
        label={<span className="font-bold">{t("authFlow.label.email")}</span>}
        className="w-full"
      >
        <TextInput placeholder={email} disabled={true} />
      </Form.Item>
      <Form.Item
        name="password"
        label={
          <span className="font-bold">{t("authFlow.label.password*")}</span>
        }
        rules={authRules.password}
        className="w-full"
      >
        <PwdInput placeholder="Create a password" />
      </Form.Item>
      <Form.Item
        name="firstName"
        label={
          <span className="font-bold">{t("authFlow.label.firstName*")}</span>
        }
        rules={authRules.firstName}
        className="w-full"
      >
        <TrimInput placeholder="Enter your first name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={
          <span className="font-bold">{t("authFlow.label.lastName")}</span>
        }
        rules={authRules.lastName}
        className="w-full"
      >
        <TrimInput placeholder="Enter your last name" />
      </Form.Item>
      <Form.Item className="w-full py-2">
        <SubmitButton form={form} loading={loading} className="w-full">
          Get Started
        </SubmitButton>
      </Form.Item>
      <Row justify="center">
        <Col className="text-center">
          <p className="text-[14px] font-normal leading-5 font-thin">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-normal text-[14px] font-bold text-purple800"
            >
              Log In
            </a>
          </p>
        </Col>
      </Row>
    </Form>
  );
};

export default GetStartedForm;
