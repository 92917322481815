import useFetchData from "hooks/useFetchData";

export const useEntityData = <T>(
  fetchAllEntities: () => Promise<T[]>,
  fetchSelectedEntities: () => Promise<T[]>
) => {
  const { data: allEntities = [], loading: allLoading } =
    useFetchData(fetchAllEntities);
  const { data: selectedEntities = [], loading: selectedLoading } =
    useFetchData(fetchSelectedEntities);

  return {
    allEntities,
    selectedEntities,
    loading: allLoading || selectedLoading,
  };
};

export default useEntityData;
