import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
// @ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

export interface StrapiError {
  message: string;
}

interface NetworkApiError extends Error {
  statusCode: number;
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("userToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const dynamicHeadersLink = setContext((_, { headers }) => {
  const tenantId = localStorage.getItem("tenantId") || 3; // FIXME: set default value for this case, we not implement tenant.
  return {
    headers: {
      ...headers,
      "Tenant-Id": tenantId,
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_BASEURL || "http://localhost:1337",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const { statusCode } = networkError as NetworkApiError;
    console.error(`[Network error]: ${networkError} with ${statusCode}`);

    if (statusCode === 500) {
      networkError.message = "Something went wrong. Please try again later.";
    }

    if (statusCode === 401) {
      // FIXME: temp solution, wait ticket for implement JWT
      localStorage.removeItem("userToken");
      window.location.reload();
    }
  }

  if (graphQLErrors) {
    for (const { message, locations, path, extensions } of graphQLErrors) {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, extensions: ${extensions}`
      );
    }
  }
});

const link = ApolloLink.from([
  errorLink,
  authLink,
  dynamicHeadersLink,
  uploadLink,
]);

const graphQLClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export default graphQLClient;
