import React, { FC } from "react";
import { Card, Row } from "antd";
import { SwitchGroupButton } from "components/Button";
import { PlusCricleIcon, CompassIcon } from "components/Icon";
import { TabConfig } from "components/Button/SwitchGroupButton";

interface Props {
  onModeChange: (value: string) => void;
  defaultValue: string;
}

interface IconProps {
  fill: string;
}

const PlusIcon: FC<IconProps> = ({ fill }) => (
  <PlusCricleIcon fill={fill} width={32} height={32} />
);
const ListIcon: FC<IconProps> = ({ fill }) => (
  <CompassIcon fill={fill} width={32} height={32} />
);

const TopScreen: React.FC<Props> = ({ onModeChange, defaultValue }) => {
  const tabConfig: TabConfig[] = [
    {
      value: "create",
      label: "Create a Roadmap",
      icon: PlusIcon,
    },
    {
      value: "list",
      label: "Your Roadmaps",
      icon: ListIcon,
    },
  ];

  return (
    <Card className="h-full w-full p-0 !shadow-none" bordered={false}>
      <Row className="font-bold text-2xl !text-gradient-base text-gradient-purpleblue pb-4">
        AI Adoption Roadmaps
      </Row>
      <Row className="flex items-center pb-2">
        <SwitchGroupButton
          onModeChange={onModeChange}
          tabConfig={tabConfig}
          defaultValue={defaultValue}
        />
      </Row>
    </Card>
  );
};

export default TopScreen;
