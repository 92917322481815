import React from "react";
import { Button } from "antd";

interface HeaderProps {
  title: string;
  description: string;
  tabStep: string;
  isViewSaveButton?: boolean;
  isDirty?: boolean;
  selectedText?: string;
  selectedCount?: number;
  isViewSelectedCount?: boolean;
  onTabContentChange: (key: string) => void;
}

const TabHeader: React.FC<HeaderProps> = ({
  title,
  description,
  tabStep,
  isViewSaveButton = true,
  isDirty = false,
  selectedText = "team",
  selectedCount = 0,
  isViewSelectedCount = true,
  onTabContentChange,
}) => {
  const teamText = selectedCount > 1 ? `${selectedText}s` : selectedText;

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-extrabold leading-9">{title}</h2>
        <div className="flex items-center">
          {isViewSelectedCount && (
            <div className="mr-2 px-4 py-2 bg-white border border-black rounded-lg">
              <span className="font-bold">
                {selectedCount} {teamText}
              </span>{" "}
              selected
            </div>
          )}
          <Button
            className="h-10"
            type="primary"
            onClick={() => onTabContentChange(tabStep)}
            disabled={isViewSaveButton && (!isDirty || selectedCount === 0)}
          >
            {isViewSaveButton ? "Save and Continue" : "Back To Roadmaps"}
          </Button>
        </div>
      </div>
      <hr className="border-t border-gray-300 mb-4" />
      <p className="text-lg font-normal leading-7">{description}</p>
    </div>
  );
};

export default TabHeader;
