import React from "react";
import { Button, Modal } from "antd";

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  onSkip: () => void;
  errors: UploadErrors;
}

export interface UploadErrors {
  missingInvalidCells: number;
  duplicatedRows: number;
}

const UploadModal: React.FC<Props> = ({
  isVisible,
  onCancel,
  onSkip,
  errors,
}) => (
  <Modal
    title="File data error!"
    open={isVisible}
    onCancel={onCancel}
    centered
    maskClosable={false}
    footer={[
      <Button key="cancel" onClick={onCancel}>
        Cancel
      </Button>,
      <Button key="skip" type="primary" onClick={onSkip}>
        Skip rows
      </Button>,
    ]}
  >
    <div className="text-red-500">
      <p>The file contains the following error(s):</p>
      <ul className="list-disc pl-6 pt-4 space-y-2">
        {errors.missingInvalidCells > 0 && (
          <li>
            {errors.missingInvalidCells} row(s) contain invalid or missing cells
          </li>
        )}
        {errors.duplicatedRows > 0 && (
          <li>{errors.duplicatedRows} row(s) are duplicated</li>
        )}
      </ul>
      <p className="pt-5">
        Do you want to continue importing the file and skip the invalid rows or
        cancel the upload?
      </p>
    </div>
  </Modal>
);

export default UploadModal;
