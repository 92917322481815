import { Rule, RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

const passwordValidator = (_: unknown, value: string) => {
  const passwordCriteria =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%&*?@])[\d!$%&*?@A-Za-z]{8,}$/;

  if (!value) return Promise.reject(new Error("Password is required."));

  if (!passwordCriteria.test(value)) {
    return Promise.reject(
      new Error(
        "Password must contain at least 8 characters, including UPPER/lowercase letters, numbers, and special characters."
      )
    );
  }
  return Promise.resolve();
};

const confirmPasswordValidator = (
  getFieldValue: (name: string) => StoreValue
) => ({
  validator(_: RuleObject, value: StoreValue) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Password mismatch."));
  },
});

const firstNameValidator = (_: unknown, value: string) => {
  if (!value || (value && !value.trim()))
    return Promise.reject(new Error("First Name is required."));
  if (value.length > 255)
    return Promise.reject(
      new Error("First Name has a max length of 255 characters.")
    );
  return Promise.resolve();
};

export const authRules = {
  email: [
    { required: true, whitespace: true, message: "Email is required." },
    { type: "email", message: "Invalid email format." },
    { max: 255, message: "Email has a max length of 255 characters." },
  ] as Rule[],
  password: [
    {
      max: 128,
      message: "Password has a max length of 128 characters.",
    },
    { validator: passwordValidator },
  ],
  confirmPassword: [
    { required: true, message: "Confirm Password is required." },
    {
      max: 128,
      message: "Confirm Password has a max length of 128 characters.",
    },
    ({ getFieldValue }: StoreValue) => confirmPasswordValidator(getFieldValue),
  ],
  firstName: [{ validator: firstNameValidator }],
  lastName: [
    { max: 255, message: "Last Name has a max length of 255 characters." },
  ],
};
