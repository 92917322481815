export const USER_ROLE = {
  ADMIN: "Admin",
  USER: "Employer",
};

export const ADMIN_ROLE = {
  SUPER_ADMIN: "Super Admin",
  BLUE_ADMIN: "Blue Admin",
};

export const COLORS = {
  PURPLE: "text-gradient-purple",
  SKY: "text-gradient-sky",
  SPACE: "text-gradient-space",
};

export const CHART_COLORS_STYLES = [COLORS.PURPLE, COLORS.SKY, COLORS.SPACE];
