import React from "react";
import { Row, Col } from "antd";

interface Props {
  code?: string;
  title?: string;
  content?: string;
}

const AuthCode: React.FC<Props> = ({ code, title, content }) => {
  return (
    <div>
      <Row justify="center">
        <Col className="font-bold py-2 text-[16px]">{title}</Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <div className="rounded-md border border-gray-300 bg-gray-50 shadow-xs px-4 py-2 text-center">
            <div className="text-sky text-4xl font-light truncate">{code}</div>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col className="text-gray-700 font-normal py-2 text-[14px]">
          {content}
        </Col>
      </Row>
    </div>
  );
};

export default AuthCode;
