import { gql } from "@apollo/client/core";

const GET_FOOTER = gql`
  query GetFooter {
    getFooter {
      columns {
        id
        title
        links {
          id
          text
          url
        }
      }
      connectWithUs {
        id
        title
        socialMediaIcons {
          id
          name
          url
        }
      }
      getInTouch {
        id
        title
        message
      }
    }
  }
`;

export { GET_FOOTER };
