import React, { FC, useState } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { MetricsSection } from "components/Section";

export interface PanelConfig {
  mode: string;
  value: number;
  label: string;
  description: string;
  isViewCurrency?: boolean;
  isGradient?: boolean;
  isHideTab?: boolean;
}

interface Props {
  onModeChange: (value: string) => void;
  panelConfig: PanelConfig[];
  defaultMode?: string;
}

const SwitchGroupPanel: FC<Props> = ({
  onModeChange,
  panelConfig = [],
  defaultMode,
}) => {
  const [mode, setMode] = useState(defaultMode || panelConfig[0].mode);

  const handleModeChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setMode(value);
    onModeChange(value);
  };
  const width = 100 / panelConfig.length;

  return (
    <Radio.Group
      className="switch-panel flex float-left"
      onChange={handleModeChange}
      value={mode}
      optionType="button"
      buttonStyle="solid"
    >
      {panelConfig.map((panel, index) => (
        <div style={{ width: `${width}%` }} key={index}>
          {panel.isHideTab ? (
            <></>
          ) : (
            <Radio.Button
              key={panel.mode}
              value={panel.mode}
              className="flex h-full items-center !border-none bg-white !shadow-gradient mt-6 mb-6 mr-3 rounded-md hover:text-purple-500"
            >
              <MetricsSection
                title={panel.label}
                value={panel.value}
                description={panel.description}
                isViewCurrency={panel.isViewCurrency}
                isGradient={panel.isGradient}
                textClassName={mode === panel.mode ? "text-white" : ""}
              />
            </Radio.Button>
          )}
        </div>
      ))}
    </Radio.Group>
  );
};

export default SwitchGroupPanel;
