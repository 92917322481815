import React from "react";
import theme from "assets/styles/theme";

import { IconProps } from "./model";

const PlusCircleIcon: React.FC<IconProps> = ({
  fill = theme.color.icon.default,
  width = 32,
  height = 32,
}) => (
  <span className="ant-menu-item-icon">
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path
        d="M16 3.25C13.4783 3.25 11.0132 3.99777 8.91648 5.39876C6.81976 6.79975 5.18556 8.79103 4.22054 11.1208C3.25552 13.4505 3.00303 16.0141 3.49499 18.4874C3.98696 20.9607 5.20127 23.2325 6.98439 25.0156C8.76751 26.7987 11.0393 28.0131 13.5126 28.505C15.9859 28.997 18.5495 28.7445 20.8792 27.7795C23.209 26.8144 25.2003 25.1802 26.6012 23.0835C28.0022 20.9868 28.75 18.5217 28.75 16C28.746 12.6197 27.4015 9.379 25.0112 6.98877C22.621 4.59854 19.3803 3.25397 16 3.25ZM16 27.25C13.775 27.25 11.5999 26.5902 9.74984 25.354C7.89979 24.1179 6.45785 22.3609 5.60636 20.3052C4.75488 18.2495 4.53209 15.9875 4.96617 13.8052C5.40025 11.6229 6.47171 9.61839 8.04505 8.04505C9.6184 6.47171 11.623 5.40025 13.8052 4.96617C15.9875 4.53208 18.2495 4.75487 20.3052 5.60636C22.3609 6.45784 24.1179 7.89978 25.354 9.74984C26.5902 11.5999 27.25 13.775 27.25 16C27.2467 18.9827 26.0604 21.8422 23.9513 23.9513C21.8422 26.0604 18.9827 27.2467 16 27.25ZM21.75 16C21.75 16.1989 21.671 16.3897 21.5303 16.5303C21.3897 16.671 21.1989 16.75 21 16.75H16.75V21C16.75 21.1989 16.671 21.3897 16.5303 21.5303C16.3897 21.671 16.1989 21.75 16 21.75C15.8011 21.75 15.6103 21.671 15.4697 21.5303C15.329 21.3897 15.25 21.1989 15.25 21V16.75H11C10.8011 16.75 10.6103 16.671 10.4697 16.5303C10.329 16.3897 10.25 16.1989 10.25 16C10.25 15.8011 10.329 15.6103 10.4697 15.4697C10.6103 15.329 10.8011 15.25 11 15.25H15.25V11C15.25 10.8011 15.329 10.6103 15.4697 10.4697C15.6103 10.329 15.8011 10.25 16 10.25C16.1989 10.25 16.3897 10.329 16.5303 10.4697C16.671 10.6103 16.75 10.8011 16.75 11V15.25H21C21.1989 15.25 21.3897 15.329 21.5303 15.4697C21.671 15.6103 21.75 15.8011 21.75 16Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default PlusCircleIcon;
