import { Row } from "antd";
import React from "react";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const BoxLayout: React.FC<Props> = ({ children, className = "" }) => {
  return (
    <Row className={`rounded-3xl m-5 w-full ${className}`}>{children}</Row>
  );
};

export default BoxLayout;
