import React, { useState } from "react";
import { Button, Row, Layout, Col, message } from "antd";
import { TextInput } from "components/Input";
import { UserCircleIcon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import { upsertRoadMap } from "services/roadMapService";

import TypeSection from "../../components/Section/TypeSection";

const { Content } = Layout;

const CreateRoadMap: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>();
  const [roadmapName, setRoadmapName] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleTabClick = (key: string) => {
    setActiveTab(key);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoadmapName(e.target.value.trim());
  };

  const handleOnClick = async () => {
    if (roadmapName.length > 250) {
      message.error("Roadmap name has a max length of 255 characters.");
      return;
    }

    setLoading(true);
    try {
      const data = await upsertRoadMap({
        name: roadmapName,
        type: "Team",
      });
      message.success("Roadmap successfully created.");
      navigate(`/adoption-roadmap/${data.id}`);
    } catch {
      message.error("Failed to create Roadmap");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="flex flex-col bg-transparent mb-6">
      <Content className="flex-grow">
        <Row className="pb-4">
          <Col span={24} className="font-bold text-lg leading-7 pb-3">
            Give this roadmap a name:
          </Col>
          <Col span={6}>
            <TextInput
              placeholder="Enter the roadmap name"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="pb-4 block">
          <div className="font-bold text-lg leading-7 pb-2">
            Choose the type of roadmap to create:
          </div>
          <div className="font-normal text-lg leading-7">
            An AI adoption roadmap is a step-by-step process that changes based
            on your starting point.
          </div>
        </Row>
        <Row className="p-4 pl-0 pt-0" gutter={[16, 16]}>
          <Col span={6}>
            <TypeSection
              title="Start With Teams"
              description="Focusing on teams narrows the roadmap to roles and tasks within those teams to start with."
              icon={UserCircleIcon}
              isActive={activeTab === "teams"}
              isCompleted={false}
              isDisabled={false}
              onClick={() => handleTabClick("teams")}
            />
          </Col>
          <Col span={6}>
            <TypeSection
              title="Start With Roles"
              description="Look across your organization to find the best opportunities to start with."
              icon={UserCircleIcon}
              isActive={activeTab === "roles"}
              isCompleted={false}
              isDisabled={true}
              onClick={() => {}}
            />
          </Col>
          <Col span={6}>
            <TypeSection
              title="Start With Tasks"
              description="You’ll start with tasks and then choose the roles or teams that will benefit the most."
              icon={UserCircleIcon}
              isActive={activeTab === "tasks"}
              isCompleted={false}
              isDisabled={true}
              onClick={() => {}}
            />
          </Col>
        </Row>
        <Row>
          <Button
            className="h-10"
            loading={loading}
            type="primary"
            onClick={handleOnClick}
            disabled={!roadmapName || !activeTab}
          >
            Create Roadmap
          </Button>
        </Row>
      </Content>
    </Layout>
  );
};

export default CreateRoadMap;
