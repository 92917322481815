import React from "react";
import { AIOpportunity } from "models/workforceModel";
import { AugmentationSection } from "components/Section";
import { Col, Empty, Row } from "antd";
import { CHART_COLORS_STYLES } from "common/constants";
import isEmpty from "lodash/isEmpty";

import TextSection from "./TextSection";

interface Props {
  data: AIOpportunity[];
  text: string;
}

const AiOpportunity: React.FC<Props> = ({ data = [], text }) => {
  if (isEmpty(data)) {
    return <Empty />;
  }

  return (
    <>
      <TextSection
        className="text-2xl font-light leading-8 w-full py-2"
        text={text}
      />
      <TextSection
        className="text-lg font-normal leading-7 pb-6"
        text={
          "Our analysis distributes the total generative AI opportunity into the categories of Expertise (knowledge-centric), People (human-centric) and Routine (transactional-oriented)."
        }
      />
      <Row gutter={[32, 0]}>
        {data.map((item, index) => (
          <Col span={8} key={index}>
            <AugmentationSection
              {...item}
              color={CHART_COLORS_STYLES[index % CHART_COLORS_STYLES.length]}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AiOpportunity;
