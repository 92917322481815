import React, { useState } from "react";
import { Col, Empty, Row } from "antd";
import { getWorkforceTeams } from "services/workforceService";
import Loader from "components/Loading/Loader";
import isEmpty from "lodash/isEmpty";
import { useSearchParams } from "react-router-dom";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";
import getAllParams from "utils/getUrlParams";
import { useFetchData } from "hooks";
import { ExploreRole, ExploreTeam } from "models/workforceModel";
import ErrorPage from "components/Router/ErrorPage";

import InfoScreen from "./InfoScreen";
import TopScreen from "./TopScreen";
import TeamScreen from "./TeamScreen/TeamScreen";
import RoleScreen from "./RoleScreen/RoleScreen";
import RoadmapScreen from "./RoadMapScreen";

const Workforce: React.FC = () => {
  const allParams = getAllParams(["team", "role"]);
  const defaultMode = isEmpty(allParams) ? "team" : allParams[0];
  const [selectedTeams, setSelectedTeams] = useState<ExploreTeam[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<ExploreRole[]>([]);

  const [mode, setMode] = useState(defaultMode);
  const [, setSearchParams] = useSearchParams();

  const {
    data: teams,
    loading,
    error,
  } = useFetchData(getWorkforceTeams, (data) =>
    data.map((item: string) => ({
      value: item,
      label: item,
    }))
  );

  const handleModeChange = (value: string) => {
    setMode(value);
    const params = new URLSearchParams();
    params.set(value, "");
    setSearchParams(params);
  };

  const handleTeamChange = (team: ExploreTeam) => {
    setSelectedTeams((prevTeams) => {
      const teamExists = prevTeams.find((t) => t.name === team.name);
      return teamExists
        ? prevTeams.filter((t) => t.name !== team.name)
        : [...prevTeams, team];
    });
  };

  const handleRoleChange = (role: ExploreRole) => {
    setSelectedRoles((prevRoles) => {
      const roleExists = prevRoles.find((r) => r.name === role.name);
      return roleExists
        ? prevRoles.filter((r) => r.name !== role.name)
        : [...prevRoles, role];
    });
  };

  const generateScreen = (screen: string) => {
    if (screen === "role") {
      return (
        <RoleScreen
          onRoleChange={handleRoleChange}
          selectedRoles={selectedRoles}
        />
      );
    }
    return (
      <TeamScreen
        selectOptions={teams || []}
        onTeamChange={handleTeamChange}
        selectedTeams={selectedTeams}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage />;
  }
  return (
    <MainLayout>
      {isEmpty(teams) ? (
        <Empty />
      ) : (
        <CardLayout>
          <Row className="bg-transparent rounded-3xl pb-4">
            <InfoScreen />
          </Row>
          <BoxLayout className="bg-white shadow-gradient">
            <Col span={19} className="flex flex-col flex-1 mr-4">
              <Row>
                <TopScreen
                  onModeChange={handleModeChange}
                  defaultValue={defaultMode}
                />
              </Row>
              <Row className="flex-1 pl-6 min-h-52">{generateScreen(mode)}</Row>
            </Col>
            <Col span={5} className="flex-1">
              <RoadmapScreen teams={selectedTeams} roles={selectedRoles} />
            </Col>
          </BoxLayout>
        </CardLayout>
      )}
    </MainLayout>
  );
};

export default Workforce;
