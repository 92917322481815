import graphQLClient from "common/client/graphQLClient";

import {
  ChangeUserPassword,
  InvitationFilters,
  LoginUser,
  RegisterUser,
  ResetPasswordFilters,
} from "../models/authModel";
import { createAppThunk } from "../common/appThunk";

import {
  LOGIN_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  RESET_PASSWORD_MUTATION,
  REGISTER_MUTATION,
  VALIDATE_INVITATION,
  VALIDATE_RESET_PWD,
} from "./authQueries";
import { fetchUserInfo } from "./userService";

const _PREFIX = "auth";

export const userLogin = async ({ username, password }: LoginUser) => {
  const response = await graphQLClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: { identifier: username, password },
  });
  const token = response.data.login.jwt;
  localStorage.setItem("userToken", token);

  await fetchUserInfo();
  return token;
};

export const forgotPassword = async (email: string) => {
  const response = await graphQLClient.mutate({
    mutation: FORGOT_PASSWORD_MUTATION,
    variables: { email },
  });
  return response.data.forgotPassword.ok;
};

export const resetPassword = createAppThunk(
  `${_PREFIX}/resetPassword`,
  async ({ code, password, passwordConfirmation }: ChangeUserPassword) => {
    const response = await graphQLClient.mutate({
      mutation: RESET_PASSWORD_MUTATION,
      variables: {
        code,
        password,
        passwordConfirmation,
      },
    });
    return response.data;
  }
);

export const register = createAppThunk(
  `${_PREFIX}/register`,
  async (payload: RegisterUser) => {
    const response = await graphQLClient.mutate({
      mutation: REGISTER_MUTATION,
      variables: {
        registerInput: payload,
      },
    });
    return response.data;
  }
);

export const validateInvitation = createAppThunk(
  `${_PREFIX}/validateInvitation`,
  async (payload: InvitationFilters) => {
    const response = await graphQLClient.query({
      query: VALIDATE_INVITATION,
      variables: {
        invitationFilters: payload,
      },
    });
    return response.data.isUserInvitationValid;
  }
);

export const validateResetPwd = createAppThunk(
  `${_PREFIX}/validateResetPwd`,
  async (payload: ResetPasswordFilters) => {
    const response = await graphQLClient.query({
      query: VALIDATE_RESET_PWD,
      variables: {
        resetPasswordFiltersInput: payload,
      },
    });
    return response.data.canUserResetPassword;
  }
);
